// react
import React from 'react';


export default function BlockLoader(props) {
    const {extraclass} = props

    return (
        <div className={"block-loader text-center mt-16 " + (extraclass ? extraclass : '')}>
            <div className="lds-roller">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );
}
