export const USER_INFO = "USER_INFO";
export const CONNECTED = "CONNECTED";
export const TOKEN = "TOKEN";
export const ID_BAILLEUR = "ID_BAILLEUR";

export const EXPLOITANT = "EXPLOITANT";

export const OFFICE_PROVINCE = "OFFICE_PROVINCE";
export const OFFICE_VILLE = "OFFICE_VILLE";

export const CHECKPOINT_PROVINCE = "CHECKPOINT_PROVINCE";
export const CHECKPOINT_VILLE = "CHECKPOINT_VILLE";

export const USER_PROV_PROVINCE = "USER_PROV_PROVINCE";
export const USER_PROV_VILLE = "USER_PROV_VILLE";

export const USER_OFFICE_PROVINCE = "USER_OFFICE_PROVINCE";
export const USER_OFFICE_VILLE = "USER_OFFICE_VILLE";

export const USER_CHKPT_PROVINCE = "USER_CHKPT_PROVINCE";
export const USER_CHKPT_VILLE = "USER_CHKPT_VILLE";

export const USER_CHECKPOINT = "USER_CHECKPOINT";
export const USER_OFFICE = "USER_OFFICE";
export const USER_MINING = "USER_MINING";
export const USER_MINING_TRACKING = "USER_MINING_TRACKING";

export const MINING_CART = "MINING_CART";
export const MINING_CART_CLIENT = "MINING_CART_CLIENT";
export const IS_MINING_CART_CLIENT = "IS_MINING_CART_CLIENT";
export const MINING_CART_ARTISANT = "MINING_CART_ARTISANT";
export const IS_MINING_CART_ARTISANT = "IS_MINING_CART_ARTISANT";

export const GAB_CART = "GAB_CART";
export const GAB_FACT = "GAB_FACT";
export const GAB_CART_CLIENT = "GAB_CART_CLIENT";
export const IS_GAB_CART_CLIENT = "IS_GAB_CART_CLIENT";
export const GAB_CART_ARTISANT = "GAB_CART_ARTISANT";
export const IS_GAB_CART_ARTISANT = "IS_GAB_CART_ARTISANT";

export const MVT_VEHICULE = "MVT_VEHICULE";
export const MVT_CLIENT = "MVT_CLIENT";

export const MVT_CART = "MVT_CART";

export const DISABLE_ADD_CART_BTN = "DISABLE_ADD_CART_BTN";

export const SET_REPORT_EXPLOITANT = "SET_REPORT_EXPLOITANT";

//export const TENANT_USERS = "TENANT_USERS";