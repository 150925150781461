import React, {useState} from "react";
import MUIDataTable from "mui-datatables";

const TestPage = (props) => {
    const columns = [
        {
            name: "name",
            label: "Name",

        },
        {
            name: "company",
            label: "Company",

        },
        {
            name: "city",
            label: "City",

        },
        {
            name: "state",
            label: "State",

        },
    ];

    const data = [
        {name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY"},
        {name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT"},
        {name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL"},
        {name: "James Houston", company: "Test Corp", city: "Dallas", state: "TX"},
    ];

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive:'vertical',
    };

    return <div className="page-content">
        <MUIDataTable
            title={"Employee List"}
            data={data}
            columns={columns}
            options={options}
        />
    </div>
}

export default TestPage;