import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { connect } from "react-redux";
import { UPDATE } from "../../store/app/appActions";
import MUIDataTable from "mui-datatables";
import BlockLoader from "../../apis/BlockLoader";
import RefreshToolBar from "../Tables/RefreshToolBar";
import MDialog from "../../components/Common/MDialog";

import ApprobationRequisitionForm from "./ApprobationRequisitionForm";
import {
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";
import moment from "moment";
import MoreOptionsTable from "../../components/Common/MoreOptionsTable";
import { apiGET } from "../../apis/apiGET";
import { apiGETSimple } from "../../apis/apiGET";

import AddToolBar from "../Tables/AddToolBar";
import firstUpper from "../../apis/firstUpper";

const options = [{ label: "Approbation", id: "edit" }];

const ITEM_HEIGHT = 48;

const ApprobationRequisitionPage = (props) => {
  const [row, setRow] = useState([]);
  const [original, setOriginal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [active, setActive] = useState(1);
  const [dialog, setDialog] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [command, setCommand] = useState([]);

  const [taxe, setTaxe] = useState([]);

  // dialog
  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogSize, setDialogSize] = useState("modal-large");

  const get_submitted_command = async () => {
    console.log("pending command are => ", props.user_info.user_type);

    let reqType = "";
    let refEntite = "";

    if (props.user_info.user_type == "provincial") {
      reqType = "Province";
      refEntite = props.user_info.province_id;
    } else if (props.user_info.user_type == "national") {
      reqType = "Province";
      refEntite = props.user_info.bureau_id;
    }else{
      reqType = "Bureau";
      refEntite = props.user_info.bureau_id;
    }

    if (props.user_info.user_type == "provincial" ) {
      const $rank = props.user_info.rank;

      const res = await apiGETSimple(
        props,
        `stock/requisition?level=${$rank}&status=1&type_Req=${reqType}&Ref_entite=${refEntite}`
      );

      if (res) {
        setRow(res);
        const new_data = res;
        console.log("res API", new_data);
        setData(new_data);
        setCommand(res);
        console.log("my command is :: ::", command);
      }
    }else{
      const $rank = (props.user_info.rank)+1;

      const res = await apiGETSimple(
        props,
        `stock/requisition?level=${$rank}&status=1&type_Req=${reqType}`
      );

      if (res) {
        setRow(res);
        const new_data = res;
        console.log("res API", new_data);
        setData(new_data);
        setCommand(res);
        console.log("my command is :: ::", command);
      }

    }
  };

  const get_taxe = async () => {
    const res = await apiGETSimple(props, `doc/taxe`);
    if (res) {
      const new_data = res;
      setTaxe(new_data);
      console.log("selected taxe here ", taxe);
    }
  };

  const openDIalog = () => {
    setOpen(true);
  };
  const closeDIalog = (val) => {
    const { refresh } = val;
    if (refresh) {
      get_submitted_command();
    }
    console.log("CLOSE");
    setOpen(false);
    //setDialog(null);da
  };

  console.log("logged user :: ", props);
  const handleMenuClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (e, $row) => {
    setAnchorEl(null);
    console.log("ROW", $row);
    if (!$row || $row === "backdropClick") return;
    setSelected($row);
    openFormDialog("edit", $row);
  };

  const openFormDialog = async ($action = "new", $data = null) => {
    setDialogSize("modal-lg");
    setDialogTitle("Validation");
    const $idalog = (
      <ApprobationRequisitionForm
        onClose={closeDIalog}
        row={$data}
        action={$action}
        taxe={taxe}
      />
    );
    setDialog($idalog);
    openDIalog();
  };

  const get_data = async ($active = 1) => {
    setLoading(true);
    const res = await apiGET(props, `app/substances?st=${$active}`);
    setLoading(false);
    if (res) {
      setRow(res.msg);
      const new_data = res.msg;
      console.log("res API", new_data);
      setData(new_data);
    }
  };

  useEffect(() => {
    console.log("ID", props.user_info.id);
    //get_data();
    get_submitted_command();
    get_taxe();
  }, []);

  const handleActiveChange = (event) => {
    setActive(event.target.value);
    get_data(event.target.value);
  };

  const column = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        print: false,
        viewColumns: false,
      },
    },

    {
      label: "Status",
      name: "is_active",
      options: {
        display: false,
        filter: false,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          const color = $row.is_active ? "green" : "red";
          return (
            <span>
              <i
                className="ri-arrow-up-circle-fill"
                style={{ fontSize: "20px", color }}
              />
            </span>
          );
        },
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
      },
    },
    {
      label: "Référence requisition",
      name: "ID_requisition",
      options: {
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          return $row.ID_requisition;
        },
      },
    },
    {
      label: "Entite",
      name: "typeReq",
      options: {
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          return $row.typeReq;
        },
      },
    },

    {
      label: "Intitulé",
      name: "typeReq",
      options: {
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          return $row.province[0].name;
        },
      },
    },
    {
      label: "Montant total",
      name: "montant_total",
      options: {
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          return `${$row.montant_total} $`;
        },
      },
    },

    {
      label: "Date Edition",
      name: "Date_edition",
      options: {
        display: true,
        filter: false,
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          // console.log(rowData)
          const $row = data.find((i) => i.id === rowData[0]);
          return moment($row.Date_edition).format("DD-MM-YYYY à HH:mm");
        },
      },
    },
    {
      label: "Edité par",
      name: "Editer_par",
      options: {
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          return $row.Editer_par;
        },
      },
    },
    {
      label: "action",
      name: "updated_at",
      options: {
        display: true,
        filter: false,
        print: false,
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          if ($row.field === "encrypt") return null;
          return (
            <MoreOptionsTable
              options={options}
              row={$row}
              anchor={anchorEl}
              open={openMenu}
              menuClick={handleMenuClick}
              menuClose={handleMenuClose}
            />
          );
        },
      },
    },
  ];

  const breadcrumbItems = [
    { title: "Gestion stock", link: "#" },
    { title: "requisition Province / Bureaux en attente", link: "#" },
  ];

  //console.log('DIALOG', dialog)
  const extraOptions = (
    <Row>
      <FormControl variant="filled" className="col-md-8">
        <InputLabel id="demo-simple-select-filled-label">
          Validation commande
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={active}
          onChange={handleActiveChange}
        >
          {/*<MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>*/}
          <MenuItem value={1}>Actif</MenuItem>
          <MenuItem value={0}>Inactif</MenuItem>
        </Select>
      </FormControl>
    </Row>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Validation requisition (Provice et bureaux) "
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <MUIDataTable
                    data={loading ? [] : data}
                    columns={loading ? [] : column}
                    options={{
                      filterType: "checkbox",
                      elevation: 0,
                      selectableRowsHeader: false,
                      selectableRows: false,
                      customToolbar: () => {
                        return (
                          <>
                            <AddToolBar refresh={() => openFormDialog()} />
                            <RefreshToolBar refresh={() => get_data(active)} />
                          </>
                        );
                      },
                    }}
                  />
                  {loading ? <BlockLoader extraclass="mt-5" /> : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <MDialog open={open} onClose={closeDIalog} size={dialogSize}>
        {dialog}
      </MDialog>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => state.app;

export default connect(mapStatetoProps, { UPDATE })(ApprobationRequisitionPage);
