import {IconButton, Tooltip, withStyles} from "@material-ui/core";
import React, {useEffect, useState} from 'react';
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import randomUrlParams from "../../apis/randomUrlParams";
import getHeader from "../../apis/getHeader";
import {toast} from "react-toastify";
import BlockLoader from "../../apis/BlockLoader";

const defaultToolbarStyles = {
    iconButton: {},
};

const PdfToolBar = (props) => {
    const [loading, setLoading] = useState(false);

    const ApiGetData = async () => {
        setLoading(true)
        let rep;
        const {id} = props
        try {
            const res = await axios.get(CONSTS.baseUrl + `pdf?id=${id}&rand=` + randomUrlParams(),
                {headers: getHeader(props.token), responseType: 'blob'});
            //setOriginal(res.data.msg);
            rep = res.data.msg
            console.log('RECEIVED')

        } catch (e) {
            const msg = e.response?.data?.msg || e.message;
            //toast.error(msg)
            console.error("React Error", e.response);
        }
        setLoading(false)

        return rep
    }

    const get_data = async ($data) => {
        const res = await ApiGetData();
        if (res) {
            console.log('res API', res)
        }
    }

    useEffect(() => {

        //setOriginal($data);
    }, []);


    const handleClick = async () => {
        if (loading) return
        console.log("clicked on icon!", props.id);
        get_data();

    }

    const {classes} = props;

    return (
        <React.Fragment>
            <Tooltip title={"Generer contrat PDF"}>


                <IconButton className={classes.iconButton} onClick={handleClick}>
                    {loading ? <i className="ri-loader-2-line"/> : <i className="ri-file-pdf-line text-red"
                                                                      style={{backgroundColor: 'transparent'}}/>}
                </IconButton>


            </Tooltip>

        </React.Fragment>
    );


}

export default withStyles(defaultToolbarStyles, {name: "CustomToolbar"})(PdfToolBar);

