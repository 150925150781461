import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import {persistor, store} from './store';
import {PersistGate} from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </BrowserRouter>
    </Provider>
);
console.dir(process.env);

ReactDOM.render(app, document.getElementById('root'));
//serviceWorker.unregister();

/*if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept();
}*/
reportWebVitals();