import {UPDATE} from "../../../store/app/appActions";
import {withNamespaces} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";

const ProfileName = (props) => {
    const [role, setRole] = useState("");

    useEffect(() => {
        let cancel;
        const {user_info} = props
        console.log("USER INFO", user_info)
        if (cancel || !user_info) return null;

        if (user_info.user_type === 'extern') {
            setRole('Client')
            return null;
        }

        if (user_info.is_superuser || user_info.user_type === 'administrateur') {
            setRole('Admin')
            return null;
        }

        if (user_info.user_type === 'intern' && user_info.role !== 'checkpoint') {
            setRole("Agent Bureau")
            return null;
        }

        if (user_info.user_type === 'intern' && user_info.role === 'checkpoint') {
            setRole("Agent Checkpoint")
            return null;
        }

        if (user_info.user_type === 'national') {
            setRole("National")
            return null;
        }

        if (user_info.user_type === 'provincial') {
            setRole("Provincial")
            return null;
        }

        return () => {
            cancel = true;
        }
    }, [props.user_info])


    return <button className='btn btn-outline-info'>{role}</button>

}

const mapStateToProps = (state) => state.app;

const mapDispatchToProps = {
    UPDATE
}

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)((withRouter(ProfileName))));
