import {connect} from "react-redux";
import {UPDATE} from "../../store/app/appActions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react";

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar5 from "../../assets/images/users/avatar-5.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"
import avatar7 from "../../assets/images/users/avatar-7.jpg"
import avatar8 from "../../assets/images/users/avatar-8.jpg"
import pencil from "../../assets/images/stencil_2.png"
import faker from 'faker';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

const LoadingContainer = props => <div>Loading...</div>;
const coordinates = [
    {lat: -4.372761787165373, lng: 15.19138276894154},
    {lat: -4.358384067427841, lng: 15.199279192281384},
    {lat: -4.366492907058081, lng: 15.207587830394944},
    {lat: -4.329988067022965, lng: 15.288502813984843},
    {lat: -4.361122701939289, lng: 15.267600420308728},
    {lat: -4.350510437655472, lng: 15.234984758687634},
    {lat: -4.3262043662676275, lng: 15.320815447164197},
    {lat: -4.3378439910128685, lng: 15.34244478066029},
    {lat: -4.337501651666356, lng: 15.366134050679822},
    {lat: -4.347429429676788, lng: 15.417064521394428},
    {lat: -4.364203653831612, lng: 15.410884711824115},
    {lat: -4.365230634986472, lng: 15.42976746328896},
    {lat: -4.37447340210843, lng: 15.416721198640522},
    {lat: -4.363518998947877, lng: 15.36831269033974},
    {lat: -4.3624004762133275, lng: 15.345225835015416},
    {lat: -4.370613387651464, lng: 15.303425333223535},
    {lat: -4.3603310094197285, lng: 15.255309693187913},
    {lat: -4.329645724119282, lng: 15.33828461330125},
    {lat: -4.334780851406607, lng: 15.35476410548875},
    {lat: -4.335807872679062, lng: 15.371930243184062},
    {lat: -4.3666178603460875, lng: 15.349614264180156},
    {lat: -4.351555357212651, lng: 15.313908697773906},
    {lat: -4.370725763313581, lng: 15.293995978047343},
    {lat: -4.373464352787242, lng: 15.250737311055156},
    {lat: -4.377572218225159, lng: 15.34274780910203},
]

const MapBailleurs = (props) => {

    const [selectedPlace, setSelectedPlace] = useState({});
    const [showingInfoWindow, setShowingInfoWindow] = useState(true);
    const [activeMarker, setActiveMarker] = useState({});
    const [original, setOriginal] = useState([]);
    const breadcrumbItems = [
        {title: "Charts", link: "#"},
        {title: "Google Maps", link: "#"},
    ];
    const [commune, setCommune] = useState('all');


    useEffect(() => {
        setOriginal(coordinates);
    }, []);


    const onMarkerClick = (props, marker, e) => {
        console.log('PROPS', props)
        setShowingInfoWindow(true)
        setActiveMarker(marker)
        setSelectedPlace(props)
    }

    const onMapClicked = (props, e, i) => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false)
            setActiveMarker(null);
        }
        //console.log('PORPS',props)
        //console.log('PORPS',e)
        //console.log("{lat:" + i.latLng.lat() + ",lng:" + i.latLng.lng()+"},")
    }


    const changeCommune = (e) => {
        const $val = e.target.value
        setCommune($val)

        switch ($val) {
            case 0:
                setOriginal(coordinates.slice(0, 12))
                break;
            case 1:
                console.log(coordinates.slice(8, 16))
                setOriginal(coordinates.slice(5, 16))
                break;
            case 2:
                console.log(coordinates.slice(15, 23))
                setOriginal(coordinates.slice(12, 23))
                break;
            default:
                setOriginal(coordinates);
        }
    }

    const imgs = [avatar1, avatar2, avatar3, avatar4, avatar5,
        avatar6, avatar7, avatar8]

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Cartegraphie" breadcrumbItems={breadcrumbItems}/>

                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <CardTitle>

                                    <Row className="mr-2 ml-2 mb-0">
                                        <div className="col-md-4 mb-4">Locataires</div>

                                        <div className="col-md-8 ">
                                            <Row className="justify-content-end">
                                                <FormControl className="col-md-4 mb-4 pr-4">
                                                    <InputLabel id="demo-simple-select-label">Province</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue={10}

                                                    >
                                                        <MenuItem value={10}>Kinshasa</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <FormControl className="col-md-4 mb-4 pr-4">
                                                    <InputLabel id="demo-simple-select-label">Ville</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue={10}>
                                                        <MenuItem value={10}>Kinshasa</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <FormControl className='col-md-4 mb-4 pr-4'>
                                                    <InputLabel id="demo-simple-select-label">Commune</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        onChange={changeCommune}
                                                        value={commune}
                                                    >
                                                        <MenuItem value='all'>Toutes les communes</MenuItem>
                                                        <MenuItem value={0}>Kinshasa</MenuItem>
                                                        <MenuItem value={1}>Gombe</MenuItem>
                                                        <MenuItem value={2}>Lingwala</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Row>

                                        </div>
                                    </Row>
                                </CardTitle>

                                <div
                                    id="gmaps-overlay"
                                    className="gmaps"
                                    style={{position: "relative", height: '400px'}}
                                >
                                    <Map
                                        google={props.google}
                                        zoom={12}
                                        onClick={onMapClicked}
                                        style={{width: "100%", height: "100%"}}
                                        initialCenter={{
                                            lat: -4.339246699999999,
                                            lng: 15.3108451
                                        }}
                                    >

                                        {original.map((r, k) => {
                                            const $rand = Math.floor((Math.random() * 7) + 1);
                                            const $amount = Math.floor((Math.random() * 200) + 20);
                                            const lat = r.lat
                                            const lng = r.lng
                                            //console.log("CCOORD", $rand)
                                            return (< Marker key={k}
                                             /*  icon={{
                                                    url: pencil,
                                                    //anchor: props.google.maps.Point(32, 32),
                                                   scaledSize: props.google.maps.Size(50, 20)
                                                }}*/
                                                             name={{
                                                                 name: faker.name.findName(),
                                                                 img: imgs[$rand],
                                                                 amount: $amount
                                                             }}
                                                             position={{lat, lng}}
                                                             onClick={onMarkerClick}/>);

                                        })}
                                        <InfoWindow visible={showingInfoWindow}
                                                    marker={activeMarker}>
                                            <div className="px-3">
                                                <h6>{selectedPlace?.name?.name}</h6>
                                                <div>
                                                    <img className="infowindow-img" src={selectedPlace?.name?.img}/>
                                                </div>
                                                <br/>
                                                <div>
                                                    IRL:
                                                    <h4 style={{fontWeight: 'bold'}}>
                                                        <strong>$ {selectedPlace?.name?.amount}</strong>
                                                    </h4>
                                                </div>

                                            </div>
                                        </InfoWindow>
                                    </Map>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
}

const mapStatetoProps = state => state.app

export default connect(mapStatetoProps, {UPDATE})(
    GoogleApiWrapper({
        apiKey: "AIzaSyCIay3y7P5doeihQHarngR1Jq8GbuDeGls",
        LoadingContainer: LoadingContainer,
        v: "3"
    })
    (MapBailleurs)
);

