import {connect} from "react-redux";
import {UPDATE} from "../../store/app/appActions";
import React, {useState} from "react";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import logodark from "../../assets/images/logo-dark.png";
import {LANDLORD} from "../../apis/utils";
import CONSTS from "../../apis/CONSTS";
import axios from "axios";
import {toast} from "react-toastify";
import {ID_BAILLEUR} from "../../store/app/appTypes";
import BlockLoader from "../../apis/BlockLoader";
import Breadcrumbs from "../../components/Common/Breadcrumb";


const BailleurRegister = (props) => {
    const [form, setForm] = useState({
        nom: "daniel",
        prenom: "dan",
        postnom: "ona",
        phone: "0898900000",
        phone2: "0898900001",
        password: "1234",
        confirm: "1234",
        email: "omotetedan@gmail.com",

    });

    const [error_pwd, setError_pwd] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event, values) => {
        const {form} = this.state;

        console.log('VALUES FORM', values)

        let $error_pwd = ''
        let error = false

        if (values.password !== values.confirm) {
            console.log('ERROR PWD');
            $error_pwd = 'Les Mots de passe ne correspondent pas'
            error = true;
        }
        setError_pwd($error_pwd)
        setForm(values)

        if (!error) {
            const st = await postRequest({...values, profile: LANDLORD});
            if (st) {
                props.history.replace('/list-bailleurs');
            }
        }

    }

    const postRequest = async (form) => {
        setLoading(true)
        let st = false;
        try {
            let res;
            const url = CONSTS.baseUrl + "user/landlord/register";
            console.log("URL", url)
            res = await axios.post(url, form,)
            toast.success(res.data?.msg || res.message);
            props.UPDATE(ID_BAILLEUR, res.data.id)
            st = true
        } catch (e) {
            console.error("React Error", e.response);
            const msg = e.response?.data?.msg || e.message;
            toast.error(msg);
        }
        setLoading(false)
        return st;

    }

    const form_view = <AvForm onValidSubmit={handleSubmit}
                              className="form-horizontal">

        <Row>
            <Col md={6}>
                <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-user-2-line auti-custom-input-icon"/>
                    <Label htmlFor="useremail">Nom</Label>
                    <AvField name="nom" value={form.nom}
                             validate={{required: true}}
                             type="text"
                             className="form-control" id="useremail"
                             placeholder="Saisir nom"/>
                </FormGroup>
            </Col>

            <Col md={6}>
                <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-user-2-line auti-custom-input-icon"/>
                    <Label htmlFor="username">Prenom</Label>
                    <AvField name="prenom"
                             validate={{required: true}}
                             value={form.prenom} type="text"
                             className="form-control"
                             placeholder="Saisir postnom"/>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col md={6}>
                <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-user-2-line auti-custom-input-icon"/>
                    <Label htmlFor="useremail">Postnom</Label>
                    <AvField name="postnom" value={form.postnom}
                             validate={{required: true}} type="text"
                             className="form-control"
                             placeholder="Saisir postnom"/>
                </FormGroup>
            </Col>

            <Col md={6}>
                <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-phone-line auti-custom-input-icon"/>
                    <Label htmlFor="username">Telephone</Label>
                    <AvField name="phone"
                             validate={{required: true}}
                             value={form.phone} type="text"
                             className="form-control"
                             placeholder="Saisir telephone"/>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col md={6}>
                <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-phone-line auti-custom-input-icon"/>
                    <Label htmlFor="useremail">Telephone 2</Label>
                    <AvField name="phone2"
                             value={form.phone2}
                             type="text"
                             className="form-control"
                             placeholder="Saisir telephone 2"/>
                </FormGroup>
            </Col>

            <Col md={6}>
                <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-mail-line auti-custom-input-icon"/>
                    <Label htmlFor="username">Email</Label>
                    <AvField name="email"
                             validate={{
                                 email: true,
                                 required: false
                             }}
                             value={form.email} type="email"
                             className="form-control" id="username"
                             placeholder="Saisir Email"/>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col md={6}>
                <FormGroup
                    className="auth-form-group-custom mb-4">
                    <i className="ri-lock-2-line auti-custom-input-icon"/>
                    <Label htmlFor="userpassword">Password</Label>
                    <AvField name="password"
                             validate={{
                                 required: true,
                                 minLength: {value: 4},
                                 maxLength: {value: 4},
                                 pattern: {value: '^[0-9]+$', errorMessage: 'Uniquement des chiffres'},
                             }}
                             value={form.password}
                             type="password"
                             className="form-control"
                             placeholder="Saisir mot de passe"/>
                </FormGroup>
                <div className="ml-2 error_pwd">
                    {error_pwd}
                </div>

            </Col>
            <Col md={6}>
                <FormGroup
                    className="auth-form-group-custom mb-4">
                    <i className="ri-lock-2-line auti-custom-input-icon"/>
                    <Label htmlFor="userpassword">Password</Label>
                    <AvField name="confirm"
                             validate={{
                                 required: true,
                                 minLength: {value: 4},
                                 maxLength: {value: 4},
                                 pattern: {value: '^[0-9]+$', errorMessage: 'Uniquement des chiffres'},
                             }}
                             value={form.confirm}
                             type="password"
                             className="form-control"
                             id="userpassword"
                             placeholder="Confirmer mot de passe"/>

                </FormGroup>
                <div className="ml-2 error_pwd">
                    {error_pwd}
                </div>

            </Col>

        </Row>


        <div className="text-cente mt-4">
            <Button color="primary" style={{width: '50%'}}
                    className="w-md waves-effect waves-light"
                    type="submit">Enregister</Button>
        </div>


    </AvForm>

    const breadcrumbItems = [
        {title: "Bailleur", link: "#"},
        {title: "creation", link: "#"},
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Nouveau bailleur"
                        breadcrumbItems={breadcrumbItems}
                    />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <p className="card-title-desc">
                                        <code>*</code> Ici sont enregistré les bailleurs ayant un
                                        contrât avec vous, prière de renseigner le numéros de
                                        télephone opérationel
                                    </p>

                                    {loading ? <BlockLoader extraclass=''/> :
                                        form_view
                                    }

                                </CardBody>
                            </Card>


                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}

const mapStatetoProps = state => state.app

export default connect(mapStatetoProps, {UPDATE})(BailleurRegister);

