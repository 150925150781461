import OfficeGuard from "../components/guards/OfficeGuard";
import {lazy} from "react";


const publicRoutes2 = [
    {
        path: "/gab/souscription",
        component: lazy(() => import("../pages/Facturation/PublicSouscription/PublicSouscription")),
        guard: OfficeGuard
    },
    {
        path: "/public/sdk",
        component: lazy(() => import("../pages/Mining/ScannerPage")),
    },


];

export {publicRoutes2}