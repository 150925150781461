import {UPDATE} from "../../store/app/appActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {useEffect, useState} from "react";
import React from 'react'
import {toast} from "react-toastify";

const OfficeGuard = (props) => {

    const [status, setStatus] = useState(null);
    useEffect(() => {
        let cancel;
        if (cancel) return
        setStatus(null)
        const {user_info} = props
        console.log("OFFICE GUARD", user_info)
        if (user_info.user_type !== 'intern') {
            toast.info('Accès reservé aux agents bureau')
            props.history.goBack()
            // props.history.replace('/dashboard')
            return
        }

        setStatus(true)

        return () => {
            cancel = true
        };
    }, [props.user_info]);


    return <React.Fragment>{status ? props.children : null}</React.Fragment>

}


const mapStateToProps = (state) => state.app;

const mapDispatchToProps = {
    UPDATE
}


export default connect(mapStateToProps, mapDispatchToProps)((withRouter(OfficeGuard)));


