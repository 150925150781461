import React, {useState} from "react";
import {

    makeStyles
} from "@material-ui/core";
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import {blue} from "@material-ui/core/colors";
import {Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const modes = [
    {icon: PaymentIcon, label: 'Mobile payment', id: 'mobile'},
    {icon: AccountBalanceIcon, label: 'Bank payment', id: 'bank'}
]

const MDialog = (props) => {
    const classes = useStyles();
    const {onClose, selectedValue, open, data, size} = props;

    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        onClose({});
    };

    return <Modal isOpen={open}  className={size ? size : "modal-md"}
                  centered={true} backdrop={true}>

        {props.children}
    </Modal>


}

export default MDialog
