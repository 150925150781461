export function get_type_name(type){
    return type==='in'?'entrant':"sortant";
}

export const RECEPTIONNISTE='receptionniste';
export const LANDLORD='landlord';
export const TENANT='tenant';
export const ADMIN='admin';


export const propName = (obj) => new Proxy(obj, {
    get(_, key) {
        return key;
    }
});
