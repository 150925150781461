import {connect} from "react-redux";
import {UPDATE} from "../../store/app/appActions";
import React, {useEffect, useState} from "react";
import {ADMIN, LANDLORD} from "../../apis/utils";
import {Alert, Button, Col, Container, FormGroup, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import logodark from "../../assets/images/logo-dark.png";
import CONSTS from "../../apis/CONSTS";
import axios from "axios";
import {toast} from "react-toastify";
import {AvField} from "availity-reactstrap-validation";
import {Grid, TextField} from "@material-ui/core";
import firstUpper from "../../apis/firstUpper";
import {TOKEN} from "../../store/app/appTypes";
import apiGetUserInfo from "../../apis/apiGetUserInfo";


const init_form = {
    province: '', ville: '', commune: "",
    quartier: '', address: "", num_address: '',
    long: "", lat: '', alt: ''
}

const reset_form = {
    province: 'kinshasa', ville: 'kinshasa', commune: "kinshasa",
    quartier: '', address: "", num_address: '',
    long: "", lat: '', alt: ''
}

const RegisterAddress = (props) => {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState(init_form);
    const [selected_address, setSelected_address] = useState('');
    const [index, setIndex] = useState(null);
    const [inputList, setInputList] = useState([]);
    const [mode, setMode] = useState("new");

    const handleAddClick = () => {
        const nForm = {...form}
        //console.log('FORM', nForm)
        setInputList([...inputList, nForm]);
        setForm({...form, address: '', num_address: ''})
    };

    const handleEditClick = () => {
        console.log('SELECTED', index);
        const nForm = {...form}
        const current_list = [...inputList]
        current_list[index] = nForm
        setInputList(current_list);
        setForm({...form, address: '', num_address: ''})
        setMode('new');
    }

    const handleInputChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const inputChange = (e) => {
        const {name, value} = e.target;
        const nform = {...form}
        nform[name] = firstUpper(value)
        console.log('NFORM', nform)
        setForm(nform)
    };
    const addressChange = (e) => {
        const $val = e.target.value;
        console.log($val)
        setSelected_address($val)
        if (e.target.value !== '') {
            setMode("edit")
            const addr = $val.split(';')
            const indexAddr = inputList.findIndex(i => i.commune === addr[0]
                && i.address === addr[1] && i.num_address === addr[2])
            setIndex(indexAddr);
            setForm({
                ...reset_form,
                commune: addr[0],
                address: addr[1],
                num_address: addr[2]
            })

        } else {
            setMode("new")
            setForm({...form, address: '', num_address: ''})
        }
    };


    const handleRemoveClick = () => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        setForm({...form, address: '', num_address: ''})
        setMode("new")
    };

    const geocoding = () => {
        inputList.forEach(d => {
            console.log('location', d);
        })
    }

    const handleSubmit = async () => {
        let $list = [...inputList]
        if (mode === 'new') {
            const nForm = {...form}
            if (nForm.address) {
                //console.log('FORM', nForm)
                $list = [...inputList, nForm]
                setForm({...form, address: '', num_address: ''})
            }

        }

        setInputList($list);


        console.log('VALUES', props)
        console.log('VALUES FORM', $list)//
        //return
        //return
        const st = await postRequest({list_addr: $list}, props.id_bailleur);
        if (st) {
            const info = await apiGetUserInfo(props, props.token);
            if (info) {
                props.history.replace('/dashboard');
            }


            props.history.replace('/dashboard');
        }
    }

    const postRequest = async (form, id) => {
        setLoading(true);
        let st = false;
        try {
            let res;
            const url = CONSTS.baseUrl + "impot/location/" + id;
            console.log("URL", url)
            res = await axios.post(url, form,)
            toast.success(res.data?.msg || res.message);
            st = true
        } catch (e) {
            console.error("React Error", e.response);
            const msg = e.response?.data?.msg || e.message;
            toast.error(msg);
        }
        //console.log('STATE', this.state)
        setLoading(false);

        return st;

    }

    const form_view = <React.Fragment>

        <Row>
            <Col md={6}>
                <FormGroup className="auth-form-group-customm mb-4">
                    <div className="">
                        <span className="input-group-textt prepend-iconn" id="basic-addon1">
                                  <i className="ri-map-2-line
                                  auti-custom-input-icon select-prefix-icon"/>
                            </span>
                        <select className="form-control select-input "
                                value={form.province}
                                name="province"
                                onChange={inputChange}>
                            <option value=''>-- Selectionner province --</option>
                            <option value='Kinshasa'>Kinshasa</option>
                        </select>
                    </div>
                </FormGroup>
            </Col>

            <Col md={6}>
                <FormGroup className="auth-form-group-customm mb-4">
                    <div className="">
                        <span className="input-group-textt prepend-iconn" id="basic-addon1">
                                  <i className="ri-map-pin-line
                                  auti-custom-input-icon select-prefix-icon"/>
                            </span>
                        <select className="form-control select-input "
                                value={form.ville}
                                name="ville"
                                onChange={inputChange}>
                            <option value=''>-- Selectionner ville --</option>
                            <option value='Kinshasa'>Kinshasa</option>
                        </select>
                    </div>
                </FormGroup>
            </Col>

        </Row>

        <Row>
            <Col md={6}>
                <FormGroup className="auth-form-group-customm mb-4">
                    <div className="">
                        <span className="input-group-textt prepend-iconn" id="basic-addon1">
                                  <i className="ri-map-pin-user-line
                                  auti-custom-input-icon select-prefix-icon"/>
                            </span>
                        <select className="form-control select-input "
                                value={form.commune}
                                name="commune"
                                onChange={inputChange}>
                            <option value=''>-- Selectionner commune --</option>
                            <option value='Kinshasa'>Kinshasa</option>
                            <option value='Ngaliema'>Ngaliema</option>
                            <option value='Gombe'>Gombe</option>
                        </select>
                    </div>
                </FormGroup>
            </Col>

            <FormGroup className="col-md-6 auth-form-group-custom mb-2">
                <div className="mInputGroup mb-3">
                    <span className="input-group-textt prepend-iconn" id="basic-addon1">
                                  <i className="ri-map-pin-5-line auti-custom-input-icon"/>
                            </span>

                    <input type="text" className="form-control mform-control"
                           placeholder="Quartier"
                           value={form.quartier}
                           name="quartier"
                           onChange={inputChange}
                           aria-describedby="basic-addon1"/>
                </div>

            </FormGroup>

        </Row>
        <Row>
            <FormGroup className="col-md-9 auth-form-group-custom mb-2">
                <div className="mInputGroup mb-3">
                    <span className="input-group-textt prepend-iconn" id="basic-addon1">
                                  <i className="ri-home-4-fill auti-custom-input-icon"/>
                            </span>

                    <input type="text" className="form-control mform-control"
                           placeholder="Avenue"
                           value={form.address}
                           name="address"
                           onChange={inputChange}
                           aria-label="Username"
                           aria-describedby="basic-addon1"/>
                </div>

            </FormGroup>


            <FormGroup className="col-md-3 auth-form-group-custom mb-2">
                <div className="mInputGroup mb-3">
                    <span className="input-group-textt prepend-iconn" id="basic-addon1">
                                  <i className="ri-keyboard-box-line auti-custom-input-icon"/>
                            </span>
                    <input type="text" className="form-control mform-control"
                           placeholder="Numero"
                           name="num_address"
                           value={form.num_address}
                           onChange={inputChange}
                           aria-label="Username"
                           aria-describedby="basic-addon1"/>
                </div>
            </FormGroup>
        </Row>


        <div className="col-md-10 mx-auto">
            <Row>
                <Button color="primary" style={{width: '100%'}}
                        className={"btn btn-circle btn-xl mt-1 col-md-5 " +
                        (mode === 'new' ? ' btn-success ' : " mbtn-warning ") +
                        (inputList.length === 0 ? ' mx-auto ' : '')}
                        onClick={mode === 'new' ? handleAddClick : handleEditClick}
                >
                    {mode === 'new' ? <span>({inputList.length}) Ajouter{' '}
                            <i className="ri-add-circle-fill
                            auti-custom-input-icon icon-align-center"/> </span> :
                        <span>Modifier <i className="ri-edit-line auti-custom-input-icon"/></span>}</Button>


                {mode === 'new' && inputList.length !== 0 ? <Button color="info"
                                                                    className="btn btn-circle btn-xl ml-md-2 mt-1 col-md-5 m"
                                                                    type="submit"
                                                                    onClick={handleSubmit}>
                    Enregistrer</Button> : null}
                {mode === 'edit' ?
                    <Button
                        className="btn btn-circle btn-xl ml-md-2 mt-1 col-md-5 mbtn-danger"
                        type="submit"
                        onClick={handleRemoveClick}>
                        Supprimer</Button> : null
                }

            </Row>

        </div>


    </React.Fragment>

    return (
        <React.Fragment>

            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters  authentication-bg">
                        <div className="col-md-8 mx-auto shadow bg-white">
                            <div className="authentication-page-content p-4 d-block align-items-center min-vh-100 pt-5">
                                <div className="w-100 pt-5">
                                    <Row className="justify-content-center">
                                        <Col lg={12}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="#" className="logo"><img src={logodark}
                                                                                           height="20"
                                                                                           alt="logo"/></Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Enregistrements adresses
                                                        locataires</h4>
                                                    <p className="text-muted">Finaliser l'enregistrement de votre
                                                        compte</p>
                                                </div>

                                                <Row className="justify-content-end mt-5" style={{marginTop: '47px'}}>
                                                    {inputList.length === 0 ? null :
                                                        <Col md={12}>
                                                            <FormGroup className="auth-form-group-customm mb-1">
                                                                <div>
                                                                     <span className="input-group-textt prepend-iconn"
                                                                           id="basic-addon1">
                                                                              <i className="ri-list-check
                                                                              auti-custom-input-icon select-prefix-icon"/>
                                                                        </span>
                                                                    <select className="form-control select-input"
                                                                            onChange={addressChange}
                                                                            value={selected_address}>
                                                                        <option value="">-- Selectionner adresse ---
                                                                        </option>
                                                                        {inputList.map((r, i) => {
                                                                            return <option key={i}
                                                                                           value={r.commune + ';' + r.address + ';' + r.num_address}>
                                                                                C/ {r.commune}, Av. {r.address},
                                                                                Numero: {r.num_address}</option>

                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                </Row>

                                                <div className="p-2 mt-1">
                                                    {form_view}

                                                </div>

                                                <div className="mt-5 text-center">

                                                    {/* <Button color="info"
                                                            className="w-md waves-effect waves-light mb-5 "
                                                            type="submit">
                                                        <Link to='/dashboard' style={{color: 'white'}}>
                                                            Passer à votre compte <i
                                                            className="ri-arrow-right-line auti-custom-input-ico"/>
                                                        </Link>
                                                    </Button>*/}

                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        {/*<Col lg={8}>
                        <div className="authentication-bg">
                            <div className="bg-overlay"></div>
                        </div>
                    </Col>*/}
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );

}

const mapStatetoProps = state => state.app

export default connect(mapStatetoProps,
    {
        UPDATE
    }
)(RegisterAddress);
