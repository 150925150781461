import { connect } from "react-redux";
import { UPDATE } from "../../store/app/appActions";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import BlockLoader from "../../apis/BlockLoader";
import { delay } from "../../apis/delay";
import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import getHeader from "../../apis/getHeader";
import { toast } from "react-toastify";

import moment from "moment";
import MoreOptionsTable from "../../components/Common/MoreOptionsTable";
import { apiGET, apiGETSimple } from "../../apis/apiGET";

const init_form = { Type_doc: "", Qte_wh: "" ,Ref_Transaction_wh :"ApproMaster"};

const WarehouseForm = (props) => {
  const { onClose, row, action, app } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(init_form);
  const [data, setData] = useState([]);
  const [rowdata, setRow] = useState([]);

  // console.log(props)

  const handleClose = () => {
    if (loading) return;
    onClose({ refresh: false });
  };
  useEffect(() => {
    let cancel;
    if (cancel) return;
    setForm(init_form);
    console.log("form", row);
    if (action === "edit") {
      console.log("form", action);
      const $form = { ...row };
      delete $form.created_at;
      delete $form.updated_at;
      setForm($form);
    }

    return () => {
      cancel = true;
    };
  }, [action]);

  useEffect(() => {
    console.log("ID", props);
    get_data();
    console.log(data);
  }, []);

  const inputsChange = (event) => {
    const cred = form;
    cred[event.target.name] = event.target.value;
    setForm((prevState) => ({ ...prevState, form }));
    delete form.form;
  };

  const handleCheckedChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    const $form = { ...form };
    console.log($form);
    e.preventDefault();
    const { st } = await postRequest($form);
    if (st) {
      onClose({ refresh: true });
    }
  };

  const get_data = async () => {
    const propsValue = props.app;
    const res = await apiGETSimple(propsValue, `doc/taxe`);
    if (res) {
      setRow(res);
      const new_data = res;
      console.log("res API List Taxe", new_data);
      setData(new_data);
      console.log("Taxe list ", new_data);
    }
  };

  const postRequest = async (form) => {
    delete form.form;
    setLoading(true);
    let st;
    try {
      const res = await axios({
        method: action === "new" ? "POST" : "PUT",
        url: CONSTS.baseUrl + "stock/stockwarehouse",
        data: form,
        headers: getHeader(props.app.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      st = true;
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  if (loading) return <BlockLoader extraclass="" />;

  return (
    <React.Fragment>
      <form className="form-horizontal" onSubmit={onSubmit}>
        <ModalHeader toggle={handleClose}>Entré stock</ModalHeader>

        <ModalBody>
          

          

          <CardBody>
            <form>
              <Row>
                <FormGroup className="col-md-6 auth-form-group-customm mb-4">
                  <div className="">
                    <span
                      className="input-group-textt prepend-iconn"
                      id="basic-addon1"
                    >
                      <i
                        className="ri-file-paper-2-line
                                  auti-custom-input-icon select-prefix-icon"
                        style={{ color: " #71AB52" }}
                      />
                    </span>
                    <select
                      className="form-control select-input "
                      value={form.Type_doc}
                      required
                      name="Type_doc"
                      onChange={inputsChange}
                    >
                      <option value="">Selectionner le type taxe -- </option>
                      {data.map((data) => {
                        return (
                          <option key={data.id} value={data.id}>
                            {data.libelle}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </FormGroup>
                <FormGroup className="col-md-6 auth-form-group-custom mb-2">
                  <div className="mInputGroup mb-3">
                    <span
                      className="input-group-textt prepend-iconn"
                      id="basic-addon1"
                    >
                      <i
                        className="ri-numbers-line auti-custom-input-icon"
                        style={{ color: " #71AB52" }}
                      />
                    </span>
                    <input
                      type="number"
                      className="form-control mform-control"
                      placeholder="Quantité à ajouter"
                      size="sm"
                      value={form.Qte_wh}
                      name="Qte_wh"
                      onChange={inputsChange}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </FormGroup>
              </Row>
            </form>
          </CardBody>
        </ModalBody>

        <ModalFooter>
          <Button
            color="success"
            type="submit"
            className="waves-effect waves-light mr-1"
          >
            <i className="ri-save-line align-middle mr-2" />
            Engegistrer
          </Button>
          <Button color="danger" className=" waves-effect waves-light mr-1">
            Annuler <i className="ri-close-line align-middle mr-2" />
          </Button>
        </ModalFooter>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = {
  UPDATE,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WarehouseForm));
