import {createTheme} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";

const muiTheme = () => createTheme({
    overrides: {
        MuiToolbar: {
            root: {
                backgroundColor: 'white',
                paddingBottom: '20px'
            },
        },
        MUIDataTableHeadCell: {
            root: {
                borderColor: '#11ff02',
                textAlign: 'right',
            },
            fixedHeader: {
                backgroundColor: 'white',
                textAlign: 'right',

            },
        },
        MuiTableCell: {
            root: {
                backgroundColor: 'white',
            },
        },
        MuiTableSortLabel: {
            root: {
                alignItems: 'flex-start',
            },
        },
        MuiTableFooter: {
            root: {
                '& .MuiToolbar-root': {
                    backgroundColor: 'white',
                },
            },
        },


    },
});

const muiCustomStyles = makeStyles({
    WhiteLine: {
        '& td': {backgroundColor: '#ffffff'},
    },
    GreyLine: {
        '& td': {backgroundColor: '#fbfbfb'},
    },

});

const muiRowStyles = (row, dataIndex, rowIndex, muiClass) => {
    // console.log('ROW INDEX', rowIndex, rowIndex % 2, muiClass)
    return {
        className: clsx({
            [muiClass.GreyLine]: rowIndex % 2 === 1,
            [muiClass.WhiteLine]: rowIndex % 2 === 0,
        }),

    };

}

const muiTableProps = () => {
    return {
        className: 'table-bordered table-striped ',
        padding: 'none', //: 'default',
        size: 'small' //: 'medium',
    };
}

export {muiTheme, muiCustomStyles, muiRowStyles, muiTableProps}