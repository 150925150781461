import React, { Component, useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
  CardImg,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InscriptionLogic from "./inscriptionLogic";

import img4 from "../../assets/images/small/img-4.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import randomUrlParams from "../../apis/randomUrlParams";
import getHeader from "../../apis/getHeader";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { UPDATE } from "../../store/app/appActions";

const LocataireInscription = (props) => {
  //class LocataireInscription extends Component {
  /*constructor(props) {
      super(props);
      this.state = {
        breadcrumbItems: [
          { title: "Locataire", link: "#" },
          { title: "Inscription", link: "#" },
        ],
        fnm: false,
        lnm: false,
        unm: false,
        city: false,
        stateV: false,
        customchk: true,
        toggleSwitch: true,
      };
    }*/
  const [addrList, setAddrList] = useState([]);
  const [loading, setLoading] = useState(false);

  const ApiGetData = async (id) => {
    setLoading(true);
    let rep;
    try {
      const res = await axios.get(
        CONSTS.baseUrl + `user/locations/${id}?rand=` + randomUrlParams(),
        { headers: getHeader(props.token) }
      );
      //setOriginal(res.data.msg);
      rep = res.data.msg;
    } catch (e) {
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
      console.error("React Error", e.response);
    }
    setLoading(false);

    return rep;
  };

  useEffect(() => {
    const { id } = props.user_info;
    console.log("poprs", id);
    ApiGetData(id).then((res) => {
      setAddrList(res);
    });
  }, []);

  const breadcrumbItems = [
    { title: "TPS", link: "#" },
    { title: "Inscription", link: "#" },
  ];

  const {
    HandleInputChanged,
    formData,
    handleSubmitForm,
    handleFileChange,
    image,
  } = InscriptionLogic(props);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Nouveau locataire"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <p className="card-title-desc">
                    <code>*</code> Ici sont enregistré les locataires ayant un
                    contrât avec vous, prière de renseigner le numéros de
                    télephone opérationel
                  </p>
                  <form onSubmit={handleSubmitForm}>
                    <Row>
                      <FormGroup className="col-md-4 auth-form-group-custom mb-2">
                        <div className="mInputGroup mb-3">
                          <span
                            className="input-group-textt prepend-iconn"
                            id="basic-addon1"
                          >
                            <i
                              className="ri-user-line auti-custom-input-icon"
                              style={{ color: " black" }}
                            />
                          </span>

                          <input
                            type="text"
                            className="form-control mform-control"
                            placeholder="Nom"
                            size="sm"
                            value={formData.nom}
                            name="nom"
                            onChange={HandleInputChanged}
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="col-md-4 auth-form-group-custom mb-2">
                        <div className="mInputGroup mb-3">
                          <span
                            className="input-group-textt prepend-iconn"
                            id="basic-addon1"
                          >
                            <i
                              className="red ri-user-line auti-custom-input-icon"
                              style={{ color: " green" }}
                            />
                          </span>

                          <input
                            type="text"
                            className="form-control mform-control"
                            placeholder="Post nom"
                            size="sm"
                            value={formData.postnom}
                            name="postnom"
                            onChange={HandleInputChanged}
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="col-md-4 auth-form-group-custom mb-2">
                        <div className="mInputGroup mb-3">
                          <span
                            className="input-group-textt prepend-iconn"
                            id="basic-addon1"
                          >
                            <i
                              className="red ri-user-line auti-custom-input-icon"
                              style={{ color: " gray" }}
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control mform-control"
                            placeholder="prenom"
                            size="sm"
                            value={formData.prenom}
                            name="prenom"
                            onChange={HandleInputChanged}
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </FormGroup>
                    </Row>

                    <Row>
                      <FormGroup className="col-md-4 auth-form-group-custom mb-2">
                        <div className="mInputGroup mb-3">
                          <span
                            className="input-group-textt prepend-iconn"
                            id="basic-addon1"
                          >
                            <i
                              className="ri-phone-line auti-custom-input-icon"
                              style={{ color: " teal" }}
                            />
                          </span>

                          <input
                            type="text"
                            className="form-control mform-control"
                            placeholder="Télephone"
                            size="sm"
                            value={formData.tel}
                            name="tel"
                            onChange={HandleInputChanged}
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="col-md-4 auth-form-group-custom mb-2">
                        <div className="mInputGroup mb-3">
                          <span
                            className="input-group-textt prepend-iconn"
                            id="basic-addon1"
                          >
                            <i
                              className="ri-money-dollar-circle-line auti-custom-input-icon"
                              style={{ color: " red" }}
                            />
                          </span>

                          <input
                            type="number"
                            className="form-control mform-control"
                            placeholder="Montant Loyer"
                            size="sm"
                            required
                            value={formData.loyer}
                            name="loyer"
                            onChange={HandleInputChanged}
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="col-md-4 auth-form-group-custom mb-2">
                        <div className="mInputGroup mb-3">
                          <span
                            className="input-group-textt prepend-iconn"
                            id="basic-addon1"
                          >
                            <i
                              className="ri-exchange-dollar-line auti-custom-input-icon"
                              style={{ color: " gray" }}
                            />
                          </span>
                          <input
                            type="text"
                            readOnly
                            className="form-control mform-control"
                            placeholder="IRL"
                            size="sm"
                            required
                            value={formData.irlPur}
                            name="IRL"
                            //  onChange={inputChange}

                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </FormGroup>
                    </Row>

                    <Row>
                      <FormGroup className="col-md-6 auth-form-group-customm mb-4">
                        <div className="">
                          <span
                            className="input-group-textt prepend-iconn"
                            id="basic-addon1"
                          >
                            <i
                              className="ri-map-pin-user-line
                                  auti-custom-input-icon select-prefix-icon"
                            />
                          </span>
                          <select
                            className="form-control select-input "
                            // value={form.commune}
                            required
                            name="adresse"
                            value={formData.adresse}
                            onChange={HandleInputChanged}
                          >
                            <option value="">
                              -- Selectionner l'addresse --
                            </option>
                            {addrList.map((r, k) => {
                              return (
                                <option key={k} value={r.id}>
                                  C/ {r.commune},Q/{r.quartier}, Av. {r.address}
                                  , Numero: {r.num_address}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </FormGroup>
                      <FormGroup className="col-md-6 auth-form-group-custom mb-2">
                        <div className="mInputGroup mb-3">
                          <span
                            className="input-group-textt prepend-iconn"
                            id="basic-addon1"
                          >
                            <i
                              className="ri-home-smile-line auti-custom-input-icon"
                              style={{ color: " teal" }}
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control mform-control"
                            placeholder="Etablissement"
                            size="sm"
                            value={formData.etablissement}
                            name="etablissement"
                            onChange={HandleInputChanged}
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </FormGroup>
                    </Row>

                    <Row>
                      <FormGroup className="col-md-12 auth-form-group-customm mb-4">
                        <div className="custom-file">
                          <Input
                            type="file"
                            size="sm"
                            name="file"
                            accept="image/*"
                            className="custom-file-input"
                            id="customFile"
                            onChange={handleFileChange}
                            onClick={(e) => e.target.files[0] && formData.file}
                          />
                          <Label
                            className="custom-file-label"
                            htmlFor="customFile"
                          >
                            Selectioner le fichier
                          </Label>
                        </div>

                        <p
                          className="ml-3 mt-2"
                          style={{
                            color: image ? "green" : "red",
                            fontSize: "16px",
                          }}
                        >
                          {image === null ? null : (
                            <i
                              className="ri-check-fill align-middle mr-2"
                              style={{ color: "green" }}
                            />
                          )}
                          {image
                            ? "1 image chargée"
                            : "Aucune image selectionné"}
                        </p>
                      </FormGroup>
                      <FormGroup className="col-md-12 auth-form-group-customm mb-4">
                        <Button
                          color="success"
                          type="submit"
                          className=" waves-effect waves-light mr-1"
                        >
                          <i className="ri-save-line align-middle mr-2" />
                          Enregistrer
                        </Button>
                        <Button
                          color="danger"
                          type="button"
                          className="waves-effect waves-light mr-1"
                        >
                          <i className="ri-close-line align-middle mr-2" />{" "}
                          Annuler
                        </Button>
                      </FormGroup>
                    </Row>
                  </form>
                </CardBody>
              </Card>

              {/*<Card>
                                <CardBody>
                                    <p className="card-title-desc">
                                        <code>prévisualiseur d'image</code>
                                    </p>

                                    <CardImg className="img-fluid" alt="Nazox"/>
                                </CardBody>
                            </Card>*/}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => state.app;

export default connect(mapStatetoProps, { UPDATE })(LocataireInscription);
