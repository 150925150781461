import React from "react";
import randomUrlParams from "./randomUrlParams";

const text = ' Aucune donnée disponible'
const widget = <div key={"0_"}><br key={"1_"}/><p key={"2_"}>
    {text}
</p></div>

const noDataFoundLabel = {
    body: {
        noMatch: widget,
    },
    pagination: {
        // next: "Next Page",
        // previous: "Previous Page",
        // rowsPerPage: "Rows per page:",
        displayRows: "de",
        jumpToPage:'Page'
    },
}

export default noDataFoundLabel;