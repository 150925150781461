import axios from "axios";

import randomUrlParams from "./randomUrlParams";
import getHeader from "./getHeader";
import {toast} from "react-toastify";
import CONSTS from "./CONSTS";
import {CONNECTED, USER_INFO} from "../store/app/appTypes";


const apiGetUserInfo = async (props, token) => {
    const {
        UPDATE
    } = props;

    const rand = randomUrlParams();
    let st = false
    let res;
    try {
        res = await axios.get(CONSTS.baseUrl + "user/info?rand=" + rand,
            {headers: getHeader(token)})
        console.log('API result', res.data.msg);
        const user = res.data.msg;
        if (user != null) {
            UPDATE(CONNECTED, true);
            UPDATE(USER_INFO, user);
            st = true
        }


    } catch (e) {
        toast.error(`Veuillez reessayer`);
        console.error('erro API get user INFo', e)
    }

    return {st, res};
};
export default apiGetUserInfo
