import React, {Component, useEffect, useState} from 'react';
import {Col, Card, CardBody, Media} from "reactstrap";
import {apiGET} from "../../apis/apiGET";
import TotalData from "./totals/TotalData";

const icons = [
    "ri-stack-line",
    "ri-store-2-line",
    "ri-briefcase-4-line"
]

const MiniWidgets = (props) => {


    // const extraCls = report.extra ? report.extra : ''

    return (
        <React.Fragment>
            <TotalData icon='ri-stack-line' title='Mining'
                       selectedProvince={props.selectedProvince}
                       func='total_exploitants'/>
            <TotalData icon='ri-store-2-line' title='Concessions'
                       selectedProvince={props.selectedProvince}
                       func='total_industriels'/>

            <TotalData icon='ri-stack-line' title='Artisanaux'
                       selectedProvince={props.selectedProvince}
                       func='total_artisanaux'/>
        </React.Fragment>
    );
}


export default MiniWidgets;
