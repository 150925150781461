// @flow

import { connect } from "react-redux";
import { UPDATE } from "../../../store/app/appActions";
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Grid, MuiThemeProvider, TextField } from "@material-ui/core";
import {
  muiCustomStyles,
  muiRowStyles,
  muiTableProps,
  muiTheme,
} from "../../../apis/mui-theme";
import firstUpper from "../../../apis/firstUpper";
import moment from "moment";
import MoreOptionsTable from "../../../components/Common/MoreOptionsTable";
import MUIDataTable from "mui-datatables";
import AddToolBar from "../../Tables/AddToolBar";
import RefreshToolBar from "../../Tables/RefreshToolBar";
import BlockLoader from "../../../apis/BlockLoader";
import { apiGET } from "../../../apis/apiGET";
import {
  MVT_CART,
  MVT_CLIENT,
  MVT_VEHICULE,
  USER_MINING,
} from "../../../store/app/appTypes";
import AutoCompSearchInput from "../../../components/Common/AutoCompSearchInput";
import MDialog from "../../../components/Common/MDialog";
import VehiculesForm from "../../Admin/Vehicules/VehiculesForm";
import { toast } from "react-toastify";
import axios from "axios";
import CONSTS from "../../../apis/CONSTS";
import getHeader from "../../../apis/getHeader";
import { Link } from "react-router-dom";
import noDataFoundLabel from "../../../apis/noDataFoundLabel";

import ProcessVerbaleForm from "./ProceVerbaleForm";
import AutorisationForm from "./Autorisation";
import CertificatForm from "./Certificat";

const options = [
  { label: "Detacher", id: "detach" },
  { label: "Previsualier", id: "preview_print" },
  { label: "Imprimer finale", id: "final_print" },
];

const UnlockedPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [docs, setDocs] = useState([]);
  const [taxe, setTaxe] = useState(null);
  const [title, setTitle] = useState("Type:");
  const [selected, setSelected] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const muiClass = muiCustomStyles();
  // dialog
  const [open, setOpen] = React.useState(false);
  const [dialogSize, setDialogSize] = useState("modal-md");
  const [dialog, setDialog] = useState(null);

  const get_docs_print_by_taxe = async (id_client: number) => {
    setLoading(true);
    const { id } = props.mvt_vehicule;
    let url = `facturation/doc_print/taxes/${id_client}?`;
    if (id) {
      url += `vehicule=${id}&`;
    }
    const res = await apiGET(props, url);
    setLoading(false);
    if (res) {
      setDocs(res.msg);
    }
  };

  const handleMenuClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (e, $row, option) => {
    setAnchorEl(null);
    // console.log('ROW', $row)
    // console.log('OPTION', option)
    if (!$row || $row === "backdropClick") return;
    setSelected($row);
    switch (option.id) {
      case "detach":
        const mvt_cart = JSON.parse(JSON.stringify(props.mvt_cart));
        const $cart_dta = mvt_cart[mvt_vehicule.id][$row.type];
        delete $cart_dta[$row.id];
        const new_cart = JSON.parse(JSON.stringify(mvt_cart));
        const new_data = JSON.parse(JSON.stringify($cart_dta));
        // console.log("NEX CART", new_cart)
        // console.log("NEX DATA", Object.values(new_data))
        props.UPDATE(MVT_CART, new_cart);
        setData(Object.values(new_data));
        break;
    }
  };

  const openVehiculeDIalog = () => {
    setDialogSize("modal-sm");
    const $idalog = (
      <VehiculesForm onClose={closeDIalog} row={null} action={"new"} />
    );
    setDialog($idalog);
    openDIalog();
  };

  const DocFormDIalog = (type: string, $data) => {
    let $dialog = null;
    console.log("TYPE", type, "DATA", $data);

    switch (type.toLowerCase()) {
      case "pv":
        setDialogSize("modal-lg");
        $dialog = (
          <ProcessVerbaleForm
            onClose={closeDIalog}
            row={$data}
            action={"new"}
            scrollable={"true"}
          />
        );
        break;
      case "certificat":
        setDialogSize("modal-lg");
        $dialog = (
          <CertificatForm onClose={closeDIalog} row={$data} action={"new"} />
        );
        break;
      case "autorisation":
        setDialogSize("modal-lg");
        $dialog = (
          <AutorisationForm onClose={closeDIalog} row={$data} action={"new"} />
        );
        break;
    }

    setDialog($dialog);
    openDIalog();
  };

  const openDIalog = () => {
    setOpen(true);
  };

  const closeDIalog = (val) => {
    const { refresh } = val;
    console.log("CLOSE");
    setOpen(false);
    //setDialog(null);
  };

  const showDOcs = (d: Object) => {
    if (!props.mvt_vehicule?.id) {
      toast.error("Veuillez selectionner un vehicule");
      return null;
    }
    const { mvt_cart, mvt_vehicule } = props;
    const $cart_dta = mvt_cart[mvt_vehicule.id][d.libelle];
    console.log("CART", d.libelle, mvt_cart[mvt_vehicule.id], $cart_dta);
    if (Object.keys($cart_dta).length === 0) {
      toast.error("Veuillez rajouter d'abord un document");
      return null;
    }
    /* for (let i = 0; i < d.docs.length; i++) {

         }*/
    console.log(mvt_vehicule, $cart_dta);
    console.log(Object.values($cart_dta));
    setData(Object.values($cart_dta));
    setTaxe(d);
    setTitle("Type: " + d.libelle);
  };

  const addDocs = (d: Object) => {
    if (!props.mvt_vehicule?.id) {
      toast.error("Veuillez selectionner un vehicule");
      return null;
    }
    const mvt_vehicule = { ...props.mvt_vehicule };
    const mvt_cart = { ...props.mvt_cart };
    const $cart_dta = mvt_cart[mvt_vehicule.id][d.libelle];
    const $docs = d.docs.filter((i) => !i.vehicule_id && !$cart_dta[i.id]);
    if ($docs.length !== 0) {
      const $doc = $docs[0];
      console.log("ODC SELECTED", $doc);
      $doc.vehicule_id = mvt_vehicule.id;
      $cart_dta[$doc.id] = $doc;
      props.UPDATE(MVT_CART, mvt_cart);
    }

    setData(Object.values($cart_dta));
    setTaxe(d);
    setTitle("Type: " + d.libelle);
    // console.log("DOCS#1", d.docs)
    // console.log("DOCS", $docs)
    console.log("MVT", mvt_cart);
  };

  // SET DEFAULT CART STRUCTURE
  useEffect(() => {
    let cancel;
    const mvt_vehicule = JSON.parse(JSON.stringify(props.mvt_vehicule));
    const mvt_cart = JSON.parse(JSON.stringify(props.mvt_cart));

    if (cancel || docs.length === 0 || !mvt_vehicule || !mvt_vehicule.id)
      return null;

    if (!mvt_cart[mvt_vehicule.id]) {
      const $cart = docs.map((d) => {
        const $docs = d.docs.filter((i) => i.vehicule_id === mvt_vehicule.id);
        const map_docs = {};
        $docs.forEach((r) => {
          map_docs[r.id] = r;
        });
        // console.log('DOCS #0', d.docs)
        // console.log('DOCS', mvt_vehicule.id, map_docs)
        return { [d.libelle]: map_docs };
      });
      const $cart_ = $cart.reduce((result, item) => {
        const key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});
      props.UPDATE(MVT_CART, { ...mvt_cart, [mvt_vehicule.id]: $cart_ });
      console.log("DEFAULT CART", $cart_);
    }
    return () => {
      cancel = true;
    };
  }, [props.mvt_vehicule, docs]);

  // GET DATA BY CLIENT
  useEffect(() => {
    let cancel;
    if (cancel || !props.mvt_client) return null;
    if (!props.mvt_client?.id) {
      setDocs([]);
      setData([]);
      return null;
    }
    const { id } = props.mvt_client;
    // console.log('MVT CLIENT', props.mvt_client)
    // console.log('MVT CART', props.mvt_cart)
    get_docs_print_by_taxe(id);

    return () => {
      cancel = true;
    };
  }, [props.mvt_client?.id]);

  const cart_data_details = ($cart_data) => {
    const $d = Object.keys(cart_dta).map((i) => cart_dta[i]);
    const $e = Object.keys($d).map((i) => $d[i]);
    // console.log("DATA DETAILS", $e)
    const final_cart = $e.map((i) => Object.values(i)[0]);
    // console.log("DATA DETAILS #2", final_cart)
    const f = $e.map((i) => Object.keys(i).length);
    const total = f.reduce((acc, obj) => acc + obj, 0);
    return { total, final_cart };
  };

  const mvt_vehicule = JSON.parse(JSON.stringify(props.mvt_vehicule));
  const mvt_cart = JSON.parse(JSON.stringify(props.mvt_cart));
  const cart_dta = mvt_cart[mvt_vehicule?.id];
  const cart_dta_details = cart_dta ? cart_data_details(cart_dta).total : 0;
  // console.log(cart_dta_details);

  const confirmCart = async () => {
    const { final_cart } = cart_data_details(cart_dta);
    console.log("FINAL CART", final_cart);
    const $data = final_cart
      .filter((d) => d !== undefined)
      .map((i) => ({ id: i.id }));
    const mvt_vehicule = JSON.parse(JSON.stringify(props.mvt_vehicule));
    const mvt_client = JSON.parse(JSON.stringify(props.mvt_client));
    const mvt_cart = JSON.parse(JSON.stringify(props.mvt_cart));

    delete mvt_cart[mvt_vehicule?.id];
    const new_cart = JSON.parse(JSON.stringify(mvt_cart));

    const $form = {
      plaque: mvt_vehicule.name,
      client_id: mvt_client.id,
      docs: $data,
    };
    console.log("CONFIRM", $data);
    console.log("form", $form);

    const { st } = await postRequest($form);
    if (st) {
      props.UPDATE(MVT_CART, new_cart);
      props.UPDATE(MVT_VEHICULE, { id: null, name: "" });
      props.UPDATE(MVT_CLIENT, { id: null, name: "" });
    }
  };

  const postRequest = async (form) => {
    setLoading(true);
    let st;
    try {
      const res = await axios({
        method: "POST",
        url: CONSTS.baseUrl + "facturation/mouvement?",
        data: form,
        headers: getHeader(props.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      st = true;
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  const column = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        print: false,
        viewColumns: false,
      },
    },

    {
      label: "Status",
      name: "is_active",
      options: {
        display: true,
        filter: false,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          // console.log('ROW', $row)
          const color = $row.preview_print_by ? "green" : "grey";
          return (
            <span>
              <i
                className="ri-arrow-up-circle-fill"
                style={{ fontSize: "20px", color }}
              />
            </span>
          );
        },
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
      },
    },
    {
      label: "ID Document",
      name: "id_doc",
      options: {
        filter: true,
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
      },
    },

    {
      label: "Type",
      name: "type",
      options: {
        display: true,
        filter: false,
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
      },
    },

    {
      label: "Créé le",
      name: "created_at",
      options: {
        display: false,
        filter: false,
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          // console.log(rowData)
          const $row = data.find((i) => i.id === rowData[0]);
          return moment($row.created_at).format("DD-MM-YYYY à HH:mm");
        },
      },
    },
    {
      label: "MàJ",
      name: "updated_at",
      options: {
        display: false,
        filter: false,
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          // console.log(rowData)
          const $row = data.find((i) => i.id === rowData[0]);
          return moment($row.updated_at).format("DD-MM-YYYY à HH:mm");
        },
      },
    },

    {
      label: "Editer",
      name: "updated_at",
      options: {
        display: true,
        filter: false,
        print: false,
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          return (
            <button
              type="button"
              onClick={() => DocFormDIalog($row.type, $row)}
              className="btn btn-outline-uccess my-2 py-1"
            >
              <i className="ri-pencil-fill" />
            </button>
          );
        },
      },
    },
    {
      label: "Actions",
      name: "updated_at",
      options: {
        display: true,
        print: false,
        filter: false,
        customHeadLabelRender: (obj) => <b>{obj.label}</b>,
        customBodyRender: (val, { rowData }) => {
          const $row = data.find((i) => i.id === rowData[0]);
          if ($row.field === "encrypt") return null;
          return (
            <MoreOptionsTable
              options={options}
              row={$row}
              anchor={anchorEl}
              open={openMenu}
              menuClick={handleMenuClick}
              menuClose={handleMenuClose}
            />
          );
        },
      },
    },
  ];

  const breadcrumbItems = [
    { title: "TPS", link: "#" },
    { title: "Mouvement", link: "#" },
  ];

  console.log(props);

  const bureau_id = props.user_info.bureau.id;
  const id_bureau = props.user_info.bureau.id_bureau;
  const title2 = "Declaration sortie vehicule - Bureau #" + id_bureau;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={title2} breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col md={4}>
              <Card>
                <CardHeader className="bg-transparent border-bottom mb-1">
                  <AutoCompSearchInput
                    url={`mining/client?bureau=${bureau_id}&fields=name,id_client,province,province_id,ville,ville_id,commune&st=1`}
                    value={props.mvt_client}
                    app={props}
                    append_all={false}
                    label="Chercher Concession"
                    onUpdate={(val) => {
                      props.UPDATE(MVT_CLIENT, val);
                      //get_data(active, false, val)
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <h5 className="font-size-14 mb-3">Documents</h5>

                  <div
                    id="accordion"
                    className="custom-accordion categories-accordion mb-3"
                  >
                    {docs.map((d: {}, k) => {
                      const cart = cart_dta ? cart_dta[d.libelle] : {};
                      // console.log("CART", cart, d.docs)
                      const total = d.docs.length;
                      const taken = d.docs.filter((i) => cart[i.id]).length;
                      return (
                        <div
                          className="categories-group-card pointer"
                          style={{
                            background:
                              taxe?.id === d.id ? "whitesmoke" : "inherit",
                          }}
                          key={k}
                        >
                          <div className={"categories-group-list"}>
                            <i className="mdi mdi-desktop-classic font-size-16 align-middle mr-2" />
                            <span
                              style={{ width: "74%", display: "inline-block" }}
                              onClick={() => showDOcs(d)}
                            >
                              ({taken}/{total}) {d.libelle}
                            </span>
                            {total === 0 ? null : (
                              <i
                                className={
                                  "mdi mdi-plus float-right accor-plus-icon pointer"
                                }
                                onClick={() => addDocs(d)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={8}>
              <Card>
                <CardBody>
                  <Row className="pl-2 mt-1 mb-3">
                    <div className="form-group  col-md-8">
                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={1}>
                          <i className="ri-search-line auti-custom-input-icon" />
                        </Grid>
                        <Grid item xs={9}>
                          <AutoCompSearchInput
                            url={`app/vehicules?fields=matricule,brand&st=1`}
                            value={props.mvt_vehicule}
                            app={props}
                            append_all={false}
                            label="Chercher plaque"
                            onUpdate={(val) => {
                              props.UPDATE(MVT_VEHICULE, val);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <div className="col-md-4 pt-2">
                      <button
                        type="submit"
                        onClick={openVehiculeDIalog}
                        className="btn btn-outline-light w-100"
                      >
                        Plaque <i className="ri-add-line" />
                      </button>
                    </div>
                  </Row>

                  <hr />
                  <Row>
                    <Col md={6}>
                      <div>
                        <Breadcrumb listClassName="p-0 bg-transparent mb-2">
                          <BreadcrumbItem>
                            <Link to="#">Documents</Link>
                          </BreadcrumbItem>
                          <BreadcrumbItem>
                            <Link to="#">
                              <b>{taxe?.libelle}</b>
                            </Link>
                          </BreadcrumbItem>
                        </Breadcrumb>
                      </div>
                    </Col>
                    <Col md={6}>
                      <button
                        type="submit"
                        onClick={() => confirmCart()}
                        disabled={cart_dta_details === 0}
                        className="btn btn-primary mb-2 float-right"
                      >
                        Confirmer <small>({cart_dta_details})</small>
                      </button>
                    </Col>
                  </Row>

                  <MuiThemeProvider theme={muiTheme()}>
                    <MUIDataTable
                      title={""}
                      data={loading ? [] : data}
                      columns={loading ? [] : column}
                      options={{
                        textLabels: noDataFoundLabel,
                        filterType: "checkbox",
                        elevation: 0,
                        selectableRowsHeader: false,
                        selectableRows: false,
                        setRowProps: (row, dataIndex, rowIndex) => {
                          return muiRowStyles(
                            row,
                            dataIndex,
                            rowIndex,
                            muiClass
                          );
                        },
                        setTableProps: muiTableProps,
                      }}
                    />
                  </MuiThemeProvider>
                  {loading ? <BlockLoader extraclass="mt-5" /> : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <MDialog open={open} onClose={closeDIalog} size={dialogSize}>
        {dialog}
      </MDialog>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => state.app;

export default connect(mapStatetoProps, { UPDATE })(UnlockedPage);
