import {
    CHECKPOINT_PROVINCE,
    CHECKPOINT_VILLE,
    CONNECTED,
    DISABLE_ADD_CART_BTN,
    EXPLOITANT,
    ID_BAILLEUR,
    IS_MINING_CART_ARTISANT,
    IS_MINING_CART_CLIENT,
    MINING_CART,
    MINING_CART_ARTISANT,
    MINING_CART_CLIENT,
    MVT_CART,
    MVT_CLIENT,
    MVT_VEHICULE,
    OFFICE_PROVINCE,
    OFFICE_VILLE,
    GAB_CART,
    TOKEN,
    USER_CHECKPOINT,
    USER_CHKPT_PROVINCE,
    USER_CHKPT_VILLE,
    USER_INFO,
    USER_MINING,
    USER_MINING_TRACKING,
    USER_OFFICE,
    USER_OFFICE_PROVINCE,
    USER_OFFICE_VILLE,
    USER_PROV_PROVINCE,
    USER_PROV_VILLE,
    GAB_CART_CLIENT,
    IS_GAB_CART_CLIENT,
    GAB_CART_ARTISANT,
    IS_GAB_CART_ARTISANT,
    GAB_FACT,
    SET_REPORT_EXPLOITANT
} from "./appTypes";
import {REHYDRATE} from "redux-persist/es/constants";

const initialState = {
    user_info: null,
    connected: false,
    token: null,
    id_bailleur: null,
    exploitant: 'all',
    office_province: 'all',
    office_ville: 'all',
    checkpoint_province: 'all',
    checkpoint_ville: 'all',
    user_prov_province: 'all',
    user_prov_ville: 'all',
    user_office_province: 'all',
    user_office_ville: 'all',
    user_chkpt_province: 'all',
    user_chkpt_ville: 'all',
    user_checkpoint: {id: 'all', name: 'Tous'},
    user_office: {id: null, name: ''},
    user_mining: {id: null, name: ''},
    user_mining_tracking: {id: null, name: ''},
    mvt_vehicule: {id: null, name: ''},
    mvt_client: {id: null, name: ''},
    mvt_cart: {},

    mining_cart: [],
    mining_cart_client: {id: -1, name: ''},
    is_mining_cart_client: false,
    mining_cart_artisan: {client_name: '', client_phone: ''},
    is_mining_cart_artisant: false,

    gab_cart: [],
    gab_fact: null,
    gab_cart_client: {id: -1, name: ''},
    is_gab_cart_client: false,
    gab_cart_artisan: {client_name: '', client_phone: ''},
    is_gab_cart_artisant: false,

    disable_add_cart_btn: false,

    report_exploitant: {id: -1, name: ''},


};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_INFO:
            return {...state, user_info: action.value}
        case CONNECTED:
            return {...state, connected: action.value}
        case TOKEN:
            return {...state, token: action.value}
        case ID_BAILLEUR:
            return {...state, id_bailleur: action.value}
        case EXPLOITANT:
            return {...state, exploitant: action.value}
        case OFFICE_PROVINCE:
            return {...state, office_province: action.value}
        case OFFICE_VILLE:
            return {...state, office_ville: action.value}
        case CHECKPOINT_PROVINCE:
            return {...state, checkpoint_province: action.value}
        case CHECKPOINT_VILLE:
            return {...state, checkpoint_ville: action.value}

        case USER_PROV_PROVINCE:
            return {...state, user_prov_province: action.value}
        case USER_PROV_VILLE:
            return {...state, user_prov_ville: action.value}

        case USER_OFFICE_PROVINCE:
            return {...state, user_office_province: action.value}
        case USER_OFFICE_VILLE:
            return {...state, user_office_ville: action.value}

        case USER_CHKPT_PROVINCE:
            return {...state, user_chkpt_province: action.value}
        case USER_CHKPT_VILLE:
            return {...state, user_chkpt_ville: action.value}

        case USER_CHECKPOINT:
            return {...state, user_checkpoint: action.value}
        case USER_OFFICE:
            return {...state, user_office: action.value}
        case USER_MINING:
            return {...state, user_mining: action.value}

        case USER_MINING_TRACKING:
            return {...state, user_mining_tracking: action.value}

        case MINING_CART:
            return {...state, mining_cart: action.value}
        case MINING_CART_CLIENT:
            return {...state, mining_cart_client: action.value}
        case IS_MINING_CART_CLIENT:
            return {...state, is_mining_cart_client: action.value}
        case MINING_CART_ARTISANT:
            return {...state, mining_cart_artisan: action.value}
        case IS_MINING_CART_ARTISANT:
            return {...state, is_mining_cart_artisant: action.value}

        case MVT_VEHICULE:
            return {...state, mvt_vehicule: action.value}
        case MVT_CLIENT:
            return {...state, mvt_client: action.value}
        case MVT_CART:
            return {...state, mvt_cart: action.value}
// GAB
        case GAB_CART:
            return {...state, gab_cart: action.value}
        case GAB_FACT:
            return {...state, gab_fact: action.value}
        case GAB_CART_CLIENT:
            return {...state, gab_cart_client: action.value}
        case IS_GAB_CART_CLIENT:
            return {...state, is_gab_cart_client: action.value}
        case GAB_CART_ARTISANT:
            return {...state, gab_cart_artisan: action.value}
        case IS_GAB_CART_ARTISANT:
            return {...state, is_gab_cart_artisant: action.value}


        case DISABLE_ADD_CART_BTN:
            return {...state, disable_add_cart_btn: action.value}

        case SET_REPORT_EXPLOITANT:
            return {...state, report_exploitant: action.value}

        /* case REHYDRATE:
             return {...state, persistedState: action.payload};*/
        default:
            return state;
    }
}


export default appReducer;
