import React, {useEffect, useState} from "react";
import {apiGET} from "../../../apis/apiGET";
import {Card, CardBody, Col, Media} from "reactstrap";
import {UPDATE} from "../../../store/app/appActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const TotalData = (props) => {
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState(null);
    const [rate, setRate] = useState(0);

    const get_data = async () => {
        let data = []
        setLoading(true);
        let url=`report/data/${props?.func}?`
        if(props.selectedProvince){
            url += `province=${props.selectedProvince}`
        }
        const res = await apiGET(props.app, url );
        setLoading(false);
        if (res) {
            setReport(res.msg);
            data = res.msg
            const $report = {value: data}
            setReport($report)

            // console.log(data)
        }
        return data;
    };

    useEffect(() => {
        get_data();
    }, [props.selectedProvince]);

    return <Col md={4}>
        <Card>
            <CardBody>
                <Media>
                    <Media body className="overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">{props?.title}</p>
                        {loading ? <b style={{color: 'grey'}}>Loading...</b> :
                            <h4 className="mb-0">{report?.value}</h4>}
                    </Media>
                    <div className="text-primary m-pointer" onClick={get_data}>
                        <i className={props?.icon + " font-size-24"}/>
                    </div>
                </Media>
            </CardBody>

            {/*<CardBody className="border-top py-3">
                <div className="text-truncate">
                    {loading ? <b style={{color: 'grey'}}>Loading...</b> : <span
                        className={"badge badge-soft-success font-size-11 mr-1 "}>
                        <i className="mdi mdi-menu-up"> </i>
                        {rate}</span>}
                    <span className="text-muted ml-2">Mois prec.</span>
                </div>
            </CardBody>*/}
        </Card>
    </Col>;
}


const mapStateToProps = (state) => state;

const mapDispatchToProps =
    {
        UPDATE
    }


export default connect(mapStateToProps, mapDispatchToProps)((withRouter(TotalData)));


