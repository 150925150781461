import React, {Component, useEffect, useState} from "react";
import {MDBDataTable} from "mdbreact";
import {Row, Col, Card, CardBody, Container} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";


import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import randomUrlParams from "../../apis/randomUrlParams";
import {toast} from "react-toastify";
import getHeader from "../../apis/getHeader";
import {connect} from "react-redux";
import {UPDATE} from "../../store/app/appActions";
import MUIDataTable from "mui-datatables";
import PdfToolBar from "./PdfToolBar";
import RefreshToolBar from "./RefreshToolBar";
import BlockLoader from "../../apis/BlockLoader";

const LocataireGrid = (props) => {
    const [row, setRow] = useState([]);
    const [original, setOriginal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const ApiGetData = async () => {
        setLoading(true);
        let rep;
        const {id} = props.user_info;
        try {
            const res = await axios.get(
                CONSTS.baseUrl + `impot/tenant/${id}?rand=` + randomUrlParams(),
                {headers: getHeader(props.token)}
            );
            //setOriginal(res.data.msg);
            rep = res.data.msg;
        } catch (e) {
            const msg = e.response?.data?.msg || e.message;
            toast.error(msg);
            console.error("React Error", e.response);
        }
        setLoading(false);

        return rep;
    };

    const get_data = async () => {
        const res = await ApiGetData();
        if (res) {
            setRow(res);
            const new_data = res;
            console.log("res API", new_data);
            setData(new_data);
        }
    };

    useEffect(() => {
        console.log('ID', props.user_info.id)
        // get_data();
    }, []);

    const column = [
        {
            label: "Numéro IRL",
            name: "irl",
            options: {},
        },
        {
            label: "Identité",
            name: "identity",
        },
        {
            label: "A payer",
            name: "total_amount",
            options: {
                display: true,
            },
        },
        {
            label: "Contrats",
            name: "contrats",
            options: {
                display: true,
            },
        },


    ];

    const breadcrumbItems = [
        {title: "TPS", link: "#"},
        {title: "Liste locataire", link: "#"},
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Base de données locataire"
                        breadcrumbItems={breadcrumbItems}
                    />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <MUIDataTable
                                        title={'Données'}
                                        data={loading ? [] : data}
                                        columns={loading ? [] : column}
                                        options={{
                                            filterType: "checkbox",
                                            elevation: 0,
                                            selectableRowsHeader: false,
                                            selectableRows: false,
                                            customToolbar: () => {
                                                return (
                                                    <>
                                                        <PdfToolBar id={props.user_info.id}/>
                                                        <RefreshToolBar refresh={() => get_data()}/>
                                                    </>
                                                );
                                            }
                                        }}
                                    />
                                    {loading ? <BlockLoader extraclass='mt-5'/> : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => state.app;

export default connect(mapStatetoProps, {UPDATE})(LocataireGrid);
