import React, {Component} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

//i18n
import {withNamespaces} from "react-i18next";

// users
import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {UPDATE} from "../../../store/app/appActions";
import {CONNECTED, GAB_CART, ID_BAILLEUR, MINING_CART, TOKEN, USER_INFO} from "../../../store/app/appTypes";
import firstUpper from "../../../apis/firstUpper";

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        // console.log('props', props.app.user_info.identity)
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
    }


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    logout() {
        this.props.UPDATE(CONNECTED, false)
        this.props.UPDATE(ID_BAILLEUR, null)
        this.props.UPDATE(USER_INFO, null)
        this.props.UPDATE(TOKEN, null)
        this.props.UPDATE(GAB_CART, [])
        this.props.UPDATE(MINING_CART, [])
        this.props.history.replace('/login');
    }

    render() {

        let username = firstUpper(this.props.app.user_info.identity ?? '');
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            const uNm = obj.email.split("@")[0];
            username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
        }

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle tag="button" className="btn header-item waves-effect"
                                    id="page-header-user-dropdown">
                        <img className="rounded-circle header-profile-user mr-1" src={avatar2} alt="Header Avatar"/>
                        <span className="d-none d-xl-inline-block ml-1 text-transform">{username}</span>
                        <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"/>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem href="#"><i className="ri-user-line align-middle mr-1"/> {this.props.t('Profile')}
                        </DropdownItem>
                        {/*<DropdownItem href="#"><i
                            className="ri-wallet-2-line align-middle mr-1"/> {this.props.t('My Wallet')}</DropdownItem>
                        <DropdownItem className="d-block" href="#"><span
                            className="badge badge-success float-right mt-1">11</span><i
                            className="ri-settings-2-line align-middle mr-1"/> {this.props.t('Settings')}</DropdownItem>
                        <DropdownItem href="#"><i
                            className="ri-lock-unlock-line align-middle mr-1"/> {this.props.t('Lock screen')}
                        </DropdownItem>*/}
                        <DropdownItem divider/>
                        <DropdownItem className="text-danger" onClick={this.logout}><i
                            className="ri-shut-down-line align-middle mr-1 text-danger"/>
                            {this.props.t('Deconnexion')}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = {
    UPDATE
}

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)((withRouter(ProfileMenu))));
