import { connect } from "react-redux";
import { UPDATE } from "../../store/app/appActions";
import {
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  ButtonGroup,
  Button,
  Col,
  Row,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import BlockLoader from "../../apis/BlockLoader";
import { delay } from "../../apis/delay";
import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import getHeader from "../../apis/getHeader";
import { toast } from "react-toastify";
import { apiGETSimple } from "../../apis/apiGET";
import moment from "moment";

const init_form = { name: "", is_active: true };

const ApprobationForm = (props) => {
  const { onClose, row, action, app } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(init_form);

  const [taxe, setTaxe] = useState(props.taxe);
  const [dataTab, setData] = useState(props.row);

  const form_init = {
    id_commande: "",
    user_name: props.app.user_info.identity.toLowerCase(),
    user_function: props.app.user_info.role,
    user_rank: props.app.user_info.rank,
  };

  const [formvalidation, setFormvalidation] = useState(form_init);

  const handleSubmitToValidation = async (e, id, subAction) => {
    //id_commande:"",Po_number:""
    console.log("get id commade", id);
    const newIDcomand = { id_commande: id };
    let final = Object.assign(formvalidation, newIDcomand);
    setFormvalidation({ ...form_init, final });
    delete form_init.final;

    const $form = formvalidation;
    console.log("Form posted data ", formvalidation);
    e.preventDefault();
    const { st } = await postRequest_submit_to_flow($form, subAction);
    if (st) {
      onClose({ refresh: true });
    }
  };

  const postRequest_submit_to_flow = async (sform, subAction) => {
    setLoading(true);

    let st;
    try {
      const res = await axios({
        method: "PUT",
        url: CONSTS.baseUrl + "stock/commande/" + subAction,
        data: sform,
        headers: getHeader(props.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      st = true;

      if (res.data.msg.id && res.data.msg.idDet) {
      } else {
      }
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  const handleClose = () => {
    if (loading) return;
    onClose({ refresh: false });
  };
  useEffect(() => {
    let cancel;

    if (cancel) return;
    setForm(init_form);
    console.log("form", row);
    if (action === "edit") {
      console.log("form", action);
      const $form = { ...row };
      delete $form.created_at;
      delete $form.updated_at;
      delete $form.edit_user;
      delete $form.add_user;
      setForm($form);
    }

    return () => {
      cancel = true;
    };
  }, [action]);

  const inputsChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleCheckedChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    const $form = { ...form };
    console.log($form);
    e.preventDefault();
    const { st } = await postRequest($form);
    if (st) {
      onClose({ refresh: true });
    }
  };

  const postRequest = async (form) => {
    setLoading(true);
    let st;
    try {
      const res = await axios({
        method: action === "new" ? "POST" : "PUT",
        url: CONSTS.baseUrl + "app/substances",
        data: form,
        headers: getHeader(app.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      st = true;
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  if (loading) return <BlockLoader extraclass="" />;

  return (
    <React.Fragment>
      <form className="form-horizontal" onSubmit={onSubmit}>
        <ModalHeader toggle={handleClose}>Validation commande</ModalHeader>

        <ModalBody>
          <Table className="table-centered mb-0 table-nowrap">
            <thead className="bg-light">
              <tr>
                <th>Document </th>
                <th>Prix</th>
                <th>Quantité</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {dataTab.command_detail.map((document, index) => {
                const valueText = taxe.filter((x) => x.id === document.taxe_id);
                console.log("Here is tab data ", valueText[0].libelle);
                /*    let tab = [];
                let final = Object.assign(tab, valueText);

                console.log("textify", final[index].id);
             const texte = valueText[0].libelle;
                console.log("textify texte ", texte);*/

                return (
                  <tr key={index}>
                    <td>
                      <i
                        className="ri-file-paper-2-line"
                        style={{ color: "green" }}
                      ></i>
                      {valueText[0].libelle}
                    </td>

                    <td>{document.montant}</td>
                    <td>{document.Qte}</td>
                    <td>{document.Qte * document.montant}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <br></br>
          <Row>
            <Col xl={6}>
              {
                <span>
                  {" "}
                  <i className="ri-pencil-line align-middle ml-2"></i>
                  <strong>Edité par : </strong>
                  {dataTab.Editer_par} , le{" "}
                  {moment(dataTab.Date_edition).format("DD-MM-YYYY à HH:mm")}
                </span>
              }
              <br></br>
              {
                <span>
                  {" "}
                  <i className="ri-user-fill align-middle ml-2"></i>
                  <strong>Qualité validateur encours : </strong>
                  <u>{props.app.user_info.role} </u>
                </span>
              }
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              color="success"
              onClick={(e) => {
                handleSubmitToValidation(e, dataTab.id, "validate");
              }}
            >
              <i class="ri-send-plane-fill align-middle ml-2"></i>
              Valider
            </Button>
            <Button
              color="danger"
              className="btn btn-outline"
              onClick={(e) => {
                handleSubmitToValidation(e, dataTab.id, "reject");
              }}
            >
              Rejeter
              <i class="ri-pencil-line align-middle ml-2"></i>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = {
  UPDATE,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApprobationForm));
