import { connect } from "react-redux";
import { UPDATE } from "../../store/app/appActions";
import {
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  ButtonGroup,
  Button,
  Col,
  Row,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import BlockLoader from "../../apis/BlockLoader";
import { delay } from "../../apis/delay";
import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import getHeader from "../../apis/getHeader";
import { toast } from "react-toastify";
import { apiGETSimple } from "../../apis/apiGET";
import moment from "moment";

const init_form = { name: "", is_active: true };

const AcceptanceRequisitionForm = (props) => {
  const { onClose, row, action, app } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(init_form);

  const [taxe, setTaxe] = useState(props.taxe);
  const [dataTab, setData] = useState(props.row);

  const form_init = {
    id_requisition: "",
    typeReq: "",
    Ref_entite: "",
    user_name: props.app.user_info.identity.toLowerCase(),
    user_function: props.app.user_info.role,
    user_rank: props.app.user_info.rank,
  };

  const [formvalidation, setFormvalidation] = useState(form_init);

  const handleSubmitToValidation = async (
    e,
    id,
    subAction,
    refEntiteForm,
    refTransForm
  ) => {
    //id_commande:"",Po_number:""

    console.log("get subaction", subAction);
    const newIDcomand = { id_requisition: id };

    let reqType = {};
    let refEntite = {};

    let refTrans = {};
    refTrans = { Ref_Transaction: refTransForm };

    let provinceId = {};

    if (props.app.user_info.user_type == "provincial") {
      reqType = { typeReq: "Province" };
      refEntite = { Ref_entite: props.app.user_info.province_id };
    } else {
      reqType = { typeReq: "Bureau" };
      refEntite = { Ref_entite: props.app.user_info.bureau_id };
    }

    provinceId = { Province_id: refEntiteForm };

    console.log(provinceId);

    if (props.app.user_info.user_type == "provincial") {
      // PUT Validate for National user on requisition
      reqType = { typeReq: "Province" };
      refEntite = { Ref_entite: refEntiteForm };

      let newRank = props.app.user_info.rank + 1;
      let user_rank = { user_rank: newRank };

      let final = Object.assign(
        formvalidation,
        newIDcomand,
        reqType,
        refEntite,
        user_rank,
        refTrans,
        provinceId
      );
      setFormvalidation({ ...form_init, final });
      delete form_init.final;

      const $form = formvalidation;
      console.log("Form posted data ", formvalidation);

      e.preventDefault();
      const { st } = await postRequest_submit_to_flow($form, subAction);
      if (st) {
        onClose({ refresh: true });
      }
    } else if (props.app.user_info.user_type == "intern") {



      reqType = { typeReq: "Bureau" };
      refEntite = { Ref_entite: refEntiteForm };

    let provinceIdRef = { Province_id: props.app.user_info.bureau.province_id };



      let newRank = 3;
      let user_rank = { user_rank: newRank };

      let final = Object.assign(
        formvalidation,
        newIDcomand,
        reqType,
        refEntite,
        user_rank,
        refTrans,
        provinceIdRef
      );
      setFormvalidation({ ...form_init, final });
      delete form_init.final;

      const $form = formvalidation;
      console.log("Form posted data ", formvalidation);

      e.preventDefault();
      const { st } = await postRequest_submit_to_flow($form, subAction);
      if (st) {
        onClose({ refresh: true });
      }
    }
  };

  const postRequest_submit_to_flow = async (sform, subAction) => {
    setLoading(true);

    let st;
    try {
      const res = await axios({
        method: "PUT",
        url: CONSTS.baseUrl + "stock/requisition/" + subAction,
        data: sform,
        headers: getHeader(props.app.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      st = true;

      if (res.data.msg.id && res.data.msg.idDet) {
      } else {
      }
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  const handleClose = () => {
    if (loading) return;
    onClose({ refresh: false });
  };
  useEffect(() => {
    let cancel;

    if (cancel) return;
    setForm(init_form);
    console.log("form", row);
    if (action === "edit") {
      console.log("form", action);
      const $form = { ...row };
      delete $form.created_at;
      delete $form.updated_at;
      delete $form.edit_user;
      delete $form.add_user;
      setForm($form);
    }

    return () => {
      cancel = true;
    };
  }, [action]);

  const inputsChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleCheckedChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    const $form = { ...form };
    console.log($form);
    e.preventDefault();
    const { st } = await postRequest($form);
    if (st) {
      onClose({ refresh: true });
    }
  };

  const postRequest = async (form) => {
    setLoading(true);
    let st;
    try {
      const res = await axios({
        method: action === "new" ? "POST" : "PUT",
        url: CONSTS.baseUrl + "app/substances",
        data: form,
        headers: getHeader(app.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      st = true;
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  if (loading) return <BlockLoader extraclass="" />;

  return (
    <React.Fragment>
      <form className="form-horizontal" onSubmit={onSubmit}>
        <ModalHeader toggle={handleClose}>Reception stock</ModalHeader>

        <ModalBody>
          <Table className="table-centered mb-0 table-nowrap">
            <thead className="bg-light">
              <tr>
                <th>Document </th>
                <th>Prix</th>
                <th>Quantité</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {dataTab.requisition_detail.map((document, index) => {
                const valueText = taxe.filter((x) => x.id === document.taxe_id);
                console.log("Here is tab data ", valueText[0].libelle);
                /*    let tab = [];
                let final = Object.assign(tab, valueText);

                console.log("textify", final[index].id);
             const texte = valueText[0].libelle;
                console.log("textify texte ", texte);*/

                return (
                  <tr key={index}>
                    <td>
                      <i
                        className="ri-file-paper-2-line"
                        style={{ color: "green" }}
                      ></i>
                      {valueText[0].libelle}
                    </td>

                    <td>{document.montant}</td>
                    <td>{document.Qte}</td>
                    <td>{document.Qte * document.montant}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <br></br>
          <Row>
            <Col xl={8}>
              {
                <span>
                  {" "}
                  <i className="ri-pencil-line align-middle ml-2"></i>
                  <strong>Edité par : </strong>
                  {dataTab.Editer_par} , le{" "}
                  {moment(dataTab.Date_edition).format("DD-MM-YYYY à HH:mm")}
                </span>
              }
              <br></br>
              {
                <span>
                  {" "}
                  <i className="ri-user-fill align-middle ml-2"></i>
                  <strong>Réception encours par : </strong>
                  <u>{props.app.user_info.role} </u>
                </span>
              }
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              color="success"
              onClick={(e) => {
                handleSubmitToValidation(
                  e,
                  dataTab.id,
                  "livraison",
                  dataTab.Ref_entite,
                  dataTab.ID_requisition
                );
              }}
            >
              <i class="ri-send-plane-fill align-middle ml-2"></i>
              Receptioner
            </Button>
            <Button
              color="danger"
              className="btn btn-outline"
              onClick={(e) => {
                handleSubmitToValidation(
                  e,
                  dataTab.id,
                  "reject",
                  dataTab.Ref_entite
                );
              }}
            >
              Rejeter
              <i class="ri-pencil-line align-middle ml-2"></i>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = {
  UPDATE,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AcceptanceRequisitionForm));
