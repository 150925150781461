const DEV_CONSTS = {
    baseUrl: 'http://127.0.0.1:8002/',
    // baseUrl: 'http://192.168.1.191:8002/',
    a: 1
}

const PROD_CONSTS = {
    baseUrl: 'https://api.mining-certificate.com/',
    a: 2
}

const CONSTS = process.env.NODE_ENV === 'development' ? DEV_CONSTS : PROD_CONSTS; //


export default CONSTS;



