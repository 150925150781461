import React, {Component, useState, useEffect} from "react";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {UPDATE} from "../../store/app/appActions";
import {TENANT} from "../../apis/utils";
import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import getHeader from "../../apis/getHeader";

const InscriptionLogic = (props) => {
    const [formData, setFormData] = useState({
        nom: "",
        postnom: "",
        prenom: "",
        tel: "",
        loyer: "",
        irlPur: "",
        adresse: "",
        fichier: "",
        etablissement: "",
    });

    const [loyer, setLoyer] = useState("");

    const [impot, setImpot] = useState("");

    const [image, setImage] = useState(null);

    function HandleInputChanged(event) {
        const cred = formData;
        cred[event.target.name] = event.target.value;
        setFormData((prevState) => ({...prevState, formData}));
        delete formData.formData;
        //console.log(formData);
        if (formData.loyer) {
            let purc = Math.round(parseInt(formData.loyer) * 0.2, 2);
            formData.irlPur = purc + " $";
        } else {
            formData.irlPur = "";
        }

        /* if (event.target.files) {
             const files = event.target.files[0];
             // const myFile=  Object.assign({}, files);
             formData.fichier = files.name;
         }*/

        //  console.log(formData);
    }

    function handleFileChange(e) {
        console.log(e.target.files)
        if (e.target.files.length) {
            const file = e.target.files[0];
            setImage(file);
        } else {
            setImage(null)
        }

    }

    async function handleSubmitForm(e) {
        e.preventDefault()
        const mForm = {...formData}
        delete mForm.formData

        mForm.contrat_data = {
            etablissement: formData.etablissement,
            amount: formData.loyer,
            percent: formData.irlPur.replace(' $', '')
        }

        mForm.profile = TENANT
        mForm.phone = mForm.tel

        delete mForm.etablissement
        delete mForm.tel
        delete mForm.loyer
        delete mForm.irlPur
        delete mForm.fichier


        console.log(mForm);


        if (image === null) {
            toast.error("Veuillez inserer une image");
            return null
        }
        const sendData = new FormData();
        sendData.append('document[]', image);
        sendData.append('data', JSON.stringify(mForm));
        const res = await postRequest(sendData)
        if (res) {
            props.history.replace('/listLocataire')
        }
    }

    const postRequest = async (form) => {
        try {
            const res = await axios.post(CONSTS.baseUrl + `impot/tenant/${props.user_info.id}`, form,
                {headers: getHeader(props.token)})
            toast.success(res.data?.msg || res.message);
            return res.data;
        } catch (e) {
            console.error("React Error", e.response);
            const msg = e.response?.data?.msg || e.message;
            console.log('msg error', msg)
            toast.error(msg);
        }

        return null;

    }


    return {HandleInputChanged, formData, handleSubmitForm, handleFileChange, image};
};


export default InscriptionLogic;
