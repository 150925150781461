import {Autocomplete} from "@material-ui/lab";
import {CircularProgress, TextField} from "@material-ui/core";
import React from 'react'
import {apiGET} from "../../apis/apiGET";
import {USER_CHECKPOINT} from "../../store/app/appTypes";

const AutoCompSearchInput = (props) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options?.length === 0;

    const autoAutoComplChange = (event, newval) => {
        console.log('event', newval)
        if (!newval) {
            setOpen(true);
            // setOptions([{id: 'all', name: 'Tous'}]);
            return;
        }
        const val = {...newval, id: newval.id, name: newval.name}
        console.log('va auto', val);
        props.onUpdate(val)
    };

    const onChangeHandle = async value => {
        console.log(value, props.app);
        const res = await apiGET(props.app, props.url + "&search=" + value);
        let f_res = res.msg
        if (props.append_all) {
            f_res = [{id: 'all', name: 'Tous'}, ...res.msg]
        }

        if (props.none_val) {
            f_res = [{id: -1, name: 'Aucun'}, ...res.msg]
        }
        // console.log('RES', res.map(i => i.name))
        setOptions(f_res);
    };

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-demo"
            fullWidth={true}
            open={open}

            onOpen={() => {
                setOpen(true);
            }}
            onClose={(e, v) => {
                console.log("CLOSE AUTO=>", v)
                setOpen(false);
            }}
            value={props.value || ''}
            filterOptions={(x) => x}
            // getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option?.name}
            options={options}
            loading={loading}
            disabled={props.disabled}
            onChange={autoAutoComplChange}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={props.variant ?? "standard"}
                    label={props.label}
                    onChange={ev => {
                        // dont fire API if the user delete or not entered anything
                        if (ev.target.value !== "" || ev.target.value !== null) {
                            onChangeHandle(ev.target.value);
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20}/>
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    );
}

export default AutoCompSearchInput;