import React, {Suspense} from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {persistConfig} from "../store";
import BlockLoader from "../apis/BlockLoader";

const LazyComponent = ($props) => {
    // const Component = $props.children;
    return <Suspense
        fallback={<BlockLoader extraclass='loader-center'/>}>
        {$props.children}</Suspense>;
}

const AppRoute = ({
                      component: Component,
                      guard: Guard,
                      layout: Layout,
                      isAuthProtected,
                      connected,
                      ...rest
                  }) => {


    return (
        <Route
            {...rest}
            render={props => {
//console.log('poprs', isAuthProtected, connected, props.location.pathname)
                /*if (isAuthProtected && !localStorage.getItem("authUser")) {*/
                if (isAuthProtected && !connected) {
                    return (
                        <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
                    );
                }

                return (
                    <Suspense
                        fallback={<BlockLoader extraclass='loader-center'/>}>
                        <Layout>
                            {Guard ? <Guard>
                                <LazyComponent p={props}><Component {...props}/></LazyComponent>
                            </Guard> : <LazyComponent p={props}><Component {...props}/></LazyComponent>
                            }

                        </Layout>
                    </Suspense>
                );
            }}
        />
    );
};

const mapStateToProps = (state) => state.app;

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);


