import {IconButton, Menu, MenuItem} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";

const MoreOptionsTable = ({menuClose, options, row}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }


    return <div>
        <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
        >
            <MoreVertIcon/>
        </IconButton>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {options.map((option) => (
                <MenuItem key={option.id} selected={option === 'null'}
                          onClick={(e) => {
                              setAnchorEl(null)
                              menuClose(e, row, option)
                          }}>
                    {option.label}
                </MenuItem>
            ))}
        </Menu>
    </div>
}

export default MoreOptionsTable