import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers';
import rootSaga from './sagas';
import storage from 'redux-persist/lib/storage'
import {persistReducer, persistStore} from "redux-persist";

/*
PERSISTANCE
 */

const persistConfig = {
    key: 'root_v26',
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

/*
SAGA REDUCER
 */


const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);


export {persistConfig, persistor, store} ;