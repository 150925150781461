import React, { Component, useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  Container,
  Button,
  Table,
  InputGroup,
  InputGroupAddon,
  Input,
  ButtonGroup,
} from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";
import MiniWidgets from "../../pages/Dashboard/MiniWidgets";

import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import randomUrlParams from "../../apis/randomUrlParams";
import { toast } from "react-toastify";
import getHeader from "../../apis/getHeader";
import { connect } from "react-redux";
import { UPDATE } from "../../store/app/appActions";
import MUIDataTable from "mui-datatables";
import BlockLoader from "../../apis/BlockLoader";
import RefreshToolBar from "../Tables/RefreshToolBar";
import AddToolBar from "../Tables/AddToolBar";
import MDialog from "../../components/Common/MDialog";
import WarehouseForm from "./stockwarehouse";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { delay } from "../../apis/delay";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import MoreOptionsTable from "../../components/Common/MoreOptionsTable";
import { apiGET, apiGETSimple } from "../../apis/apiGET";

import img1 from "../../assets/images/product/document_picture.png";
import img2 from "../../assets/images/product/document_picture.png";
import img3 from "../../assets/images/product/document_picture.png";
import img4 from "../../assets/images/product/document_picture.png";

const CommandePAge = (props) => {
  const breadcrumbItems = [
    { title: "Gestion stock", link: "#" },
    { title: "Approvisionement", link: "#" },
  ];

  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = useState("");

  const [activeTab, setactiveTab] = useState(1);
  const [activeTab1, setactiveTab1] = useState(5);
  const [activeTab2, setactiveTab2] = useState(9);
  const [activeTab3, setactiveTab3] = useState(13);
  const [customActiveTab, setcustomActiveTab] = useState(5);
  const [activeTabJustify, setactiveTabJustify] = useState(5);
  const [col1, setCol1] = useState(false);
  const [col2, setCol2] = useState(false);
  const [col3, setCol3] = useState(false);
  const [col5, setCol5] = useState(false);

  const [colindex, setColIdex] = useState([]);

  const [Totalcaart, setCart] = useState(0);
  const [supplier, setSupplier] = useState([]);
  const [command_detail, setCommand_det] = useState([]);
  const [command, setCommand] = useState([]);

  const [reportData, setReport] = useState([]);

  const [stock, setStock] = useState([]);

  const init_form = {
    id_fournisseur: "",
    Editer_par: props.user_info.identity.toLowerCase(),
    detail_command: {},
    montant_total: "0",
  };
  const [form, setForm] = useState(init_form);

  const submit_form = { id_commande: "", Po_number: "" };
  const [sform, setSform] = useState(submit_form);

  const { onClose, row, action, app } = props;
  const [loading, setLoading] = useState(false);

  const t_col1 = () => {
    console.log("tcol1 :", col1, "!col1", !col1);
    if (col1 == false) {
      setCol1(true);
      setCol2(false);
      setCol3(false);
    } else {
      setCol1(false);
    }
  };

  const t_col2 = () => {
    console.log("tcol2 :", col2);
    if (col2 == false) {
      setCol1(false);
      setCol3(false);
      setCol2(true);
    } else {
      setCol2(false);
    }
  };

  const t_col3 = () => {
    if (col3 == false) {
      setCol2(false);
      setCol1(false);
      setCol3(true);
    } else {
      setCol3(false);
    }
  };

  const t_col5 = () => {
    setCol5(col5);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };
  const toggle2 = (tab) => {
    if (activeTab2 !== tab) {
      setactiveTab2(tab);
    }
  };
  const toggle3 = (tab) => {
    if (activeTab3 !== tab) {
      setactiveTab3(tab);
    }
  };

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab);
      console.log("", tab);
    }
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const handleIncrement = (counter) => {
    const counters = [...command_detail];
    const index = counters.findIndex((x) => x.id === counter);
    counters[index].Qte++;
    counters[index].total = counters[index].Qte * counters[index].pu;
    setCommand_det([...counters]);
    let tot = 0;
    command_detail.map((c) => {
      tot += c.total;
    });
    setCart(tot);
    const newAmoumt = { montant_total: tot };
    const detail_comm = { detail_command: command_detail };

    let final = Object.assign(form, newAmoumt, detail_comm);
    setForm({ ...form, final });
    delete form.final;

    console.log(form);
  };

  const handleDecrement = (counter) => {
    const counters = [...command_detail];
    const index = counters.findIndex((x) => x.id === counter);

    if (counters[index].Qte > 1) {
      counters[index].Qte -= 1;
      counters[index].total = counters[index].Qte * counters[index].pu;
      setCommand_det([...counters]);
    } else {
      counters[index].Qte = 1;
      counters[index].total = counters[index].Qte * counters[index].pu;
      setCommand_det([...counters]);
    }
    let tot = 0;
    command_detail.map((c) => {
      tot += c.total;
    });
    setCart(tot);
    const newAmoumt = { montant_total: tot };
    const detail_comm = { detail_command: command_detail };

    let final = Object.assign(form, newAmoumt, detail_comm);
    setForm({ ...form, final });

    console.log(form);
  };

  const inputsChange = (event) => {
    const counter = Number(event.target.id);
    const counters = [...command_detail];
    const index = command_detail.findIndex((x) => x.id === counter);
    let tot = 0;
    if (Number(event.target.value) > 0) {
      counters[index].Qte = Number(event.target.value);
      counters[index].total = Number(event.target.value) * counters[index].pu;
      setCommand_det([...counters]);
      command_detail.map((c) => {
        tot += c.total;
      });
      setCart(tot);
      const newAmoumt = { montant_total: tot };
      const detail_comm = { detail_command: command_detail };
      let final = Object.assign(form, newAmoumt, detail_comm);
      setForm({ ...form, final });
      delete form.final;
      console.log(form);
    } else {
      counters[index].Qte = 0;
      counters[index].total = 0;
      setCart(0);
      const newAmoumt = { montant_total: 0 };
      let final = Object.assign(form, newAmoumt);
      setForm({ ...form, final });
      delete form.final;
    }
  };

  const removeCartItem = (id) => {
    const newSte = command_detail.filter((tab) => tab.id !== id);
    setCommand_det([...newSte]);
    let tot = 0;
    newSte.map((c) => {
      tot += c.total;
    });
    setCart(tot);
    const newAmoumt = { montant_total: tot };
    const detail_comm = { detail_command: newSte };
    let final = Object.assign(form, newAmoumt, detail_comm);
    setForm({ ...form, final });
    delete form.final;
  };

  const get_fournisseur = async () => {
    const res = await apiGETSimple(props, `doc/Fournisseur`);
    if (res) {
      setSupplier(res);
    }
  };

  const get_taxe = async () => {
    const res = await apiGETSimple(props, `doc/taxe`);
    if (res) {
      res.map((p, k) => {
        let final = Object.assign(
          res[k],
          { Qte: 0 },
          { montant: p.pu },
          { total: 0 },
          { Id_taxe: p.id }
        );
        res[k] = final;
      });

      setCommand_det(res);
      setCart(0);
    }
  };

  const get_submitted_command = async () => {
    const res = await apiGETSimple(props, `stock/commande`);
    const res2 = await apiGETSimple(props, `stock/commande_det?st_commande=0`);

    if (res) {
      const newdata = res;
      setCommand(res);
      /*  newdata.map((p, k) => {
        const detail = res2.filter((x) => x.commande_id === p.id);
        let final = Object.assign(newdata[k], { detail: p.command_detail });
        newdata[k] = final;
      });
      setCommand(newdata);*/

      console.log("my comman size::", stock);
    }
  };

  const get_stock_dump = async () => {
    const res = await apiGETSimple(
      props,
      `stock/dumpStock?Entite=National&code=1`
    );

    if (res) {
      //  setRow(res);
      const new_data = res;
      const newdata = res;
      console.log("res API Dump", new_data);

      newdata.map((p, k) => {
        let final = Object.assign(newdata[k], {
          icon: "ri-file-paper-2-line",
          title: p.Decription[0].libelle,
          value: `${p.Qte} `,
          rate: "",
          desc: `${p.Qte * p.Decription[0].pu} $`,
        });
        newdata[k] = final;
      });
      setReport(newdata);
      setStock(res);
     
    }
  };

  const handleselectChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmitToValidation = async (e, id, po) => {
    //id_commande:"",Po_number:""
    const newIDcomand = { id_commande: id };
    const newPoNumber = { Po_number: po };
    let final = Object.assign(sform, newIDcomand, newPoNumber);
    setSform({ ...sform, final });
    delete sform.final;

    const $sform = { ...sform };
    console.log($sform);
    e.preventDefault();
    const ret = await postRequest_submit_to_flow($sform);
    if (ret) {
      console.log("rendered after is ", command);
    }
  };

  const onSubmit = async (e) => {
    const $form = { ...form };
    console.log($form);
    e.preventDefault();
    const ret = await postRequest($form);
    console.log(ret);
  };

  const postRequest = async (form) => {
    delete form.final;
    setLoading(true);

    let st;
    try {
      const res = await axios({
        method: "POST",
        url: CONSTS.baseUrl + "stock/commande",
        data: form,
        headers: getHeader(props.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      get_taxe();
      get_submitted_command();
      if (res.data.msg.id && res.data.msg.idDet) {
        get_taxe();
        get_submitted_command();
      }

      st = true;
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  const postRequest_submit_to_flow = async (sform) => {
    setLoading(true);

    let st;
    try {
      const res = await axios({
        method: "PUT",
        url: CONSTS.baseUrl + "stock/commande/Sendonflow",
        data: sform,
        headers: getHeader(props.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      get_taxe();
      get_submitted_command();
      t_col1();

      if (res.data.msg.id && res.data.msg.idDet) {
      } else {
        console.log("not executed ", command);
        get_submitted_command();
      }
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  useEffect(() => {
    toggleCustomJustified("5");
    get_submitted_command();
    get_stock_dump();
    toggle("1");
    t_col1();
    get_fournisseur();
    get_taxe();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Commandes & stock"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom ">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "5",
                        })}
                        onClick={() => {
                          toggleCustomJustified("5");
                        }}
                        style={{ color: " #71AB52" }}
                      >
                        <span
                          className="d-none d-sm-block"
                          style={{ color: "black" }}
                        >
                          {" "}
                          <i
                            class="ri-pencil-fill"
                            style={{ color: " #71AB52" }}
                          ></i>{" "}
                          Edition
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "6",
                        })}
                        onClick={() => {
                          toggleCustomJustified("6");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i
                            class="ri-file-list-line"
                            style={{ color: " #71AB52" }}
                          ></i>{" "}
                          Etat Stock Central
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "7",
                        })}
                        onClick={() => {
                          toggleCustomJustified("7");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          {" "}
                          <i
                            class="ri-organization-chart"
                            style={{ color: " #71AB52" }}
                          ></i>
                          Stock Province & bureau
                        </span>
                      </NavLink>
                    </NavItem>
                    {/*
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "8",
                        })}
                        onClick={() => {
                          toggleCustomJustified("8");
                        }}
                      >
                        <span className="d-none d-sm-block">Settings</span>
                      </NavLink>
                    </NavItem>
                    */}
                  </Nav>

                  <TabContent activeTab={activeTabJustify}>
                    <TabPane tabId="5" className="p-3">
                      <Row>
                        <Col sm="6">
                          <h4 className="card-title">Nouvelle commande</h4>

                          <form className="form-horizontal" onSubmit={onSubmit}>
                            <Row className="pl-2 mt-1">
                              <div className="form-group  col-md-12">
                                <Grid
                                  container
                                  spacing={40}
                                  alignItems="flex-end"
                                >
                                  <Grid item xs={1}>
                                    <i className="ri-pencil-line auti-custom-input-icon" />
                                  </Grid>
                                  <Grid item xs={11}>
                                    <TextField
                                      id="input-with-icon-grid"
                                      label=""
                                      autoComplete="off"
                                      required
                                      fullWidth={true}
                                      readonly={true}
                                      value={props.user_info.identity.toLowerCase()}
                                      /*   name="name" onChange={inputsChange}*/
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </Row>

                            <Row className="px-2 pl-2 mt-1">
                              <div className="form-group  col-md-12 mb-1">
                                <Grid
                                  container
                                  spacing={40}
                                  alignItems="flex-end"
                                >
                                  <Grid item xs={1}>
                                    <i class="ri-luggage-deposit-line auti-custom-input-icon"></i>
                                  </Grid>
                                  <Grid item xs={11} className="row">
                                    <FormControl className="select-m-top col-md-12">
                                      <InputLabel id="demo-simple-select-filled-label">
                                        Fournisseur
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        name="province"
                                        required
                                        fullWidth={true}
                                        value={form.id_fournisseur}
                                        name="id_fournisseur"
                                        onChange={handleselectChange}
                                      >
                                        <MenuItem value="">
                                          <em>Aucun</em>
                                        </MenuItem>

                                        {supplier.map((p, k) => {
                                          return (
                                            <MenuItem key={k} value={p.id}>
                                              {p.name}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                    <input
                                      type="hidden"
                                      id="input-with-icon-grid"
                                      label="Montant total"
                                      autoComplete="off"
                                      required
                                      fullWidth={true}
                                      readonly={false}
                                      value={form.montant_total}
                                      name="montant_total"
                                      /*   name="name" onChange={inputsChange}*/
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </Row>

                            <br></br>

                            <div className="table-responsive">
                              <Table className="table-centered mb-0 table-nowrap">
                                <thead className="bg-light">
                                  <tr>
                                    <th>Document </th>
                                    <th>Prix</th>
                                    <th>Quantité</th>
                                    <th>Total</th>
                                    <th className="text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {command_detail.map((product, key) => (
                                    <tr key={key}>
                                      <td>
                                        <h5 className="font-size-14 text-truncate">
                                          <Link
                                            to={
                                              "/ecommerce-product-detail/" +
                                              product.id
                                            }
                                            className="text-dark"
                                          >
                                            {product.libelle}
                                          </Link>
                                        </h5>
                                      </td>
                                      <td>$ {product.pu}</td>
                                      <td>
                                        <div
                                          style={{ width: "120px" }}
                                          className="product-cart-touchspin"
                                        >
                                          <InputGroup>
                                            <InputGroupAddon
                                              addonType="prepend"
                                              className="input-group-btn"
                                            >
                                              <Button
                                                color="primary"
                                                className="bootstrap-touchspin-down"
                                                onClick={() => {
                                                  handleDecrement(
                                                    product.id,
                                                    product.Qte
                                                  );
                                                }}
                                              >
                                                -
                                              </Button>
                                            </InputGroupAddon>

                                            <Input
                                              type="text"
                                              id={product.id}
                                              value={product.Qte}
                                              onChange={inputsChange}
                                            />
                                            <InputGroupAddon
                                              addonType="append"
                                              className="input-group-btn"
                                            >
                                              <Button
                                                color="primary"
                                                className="bootstrap-touchspin-down"
                                                onClick={() => {
                                                  handleIncrement(
                                                    product.id,
                                                    product.Qte
                                                  );
                                                }}
                                              >
                                                +
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </div>
                                      </td>
                                      <td>$ {product.total}</td>
                                      <td
                                        style={{ width: "90px" }}
                                        className="text-center"
                                      >
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            removeCartItem(product.id)
                                          }
                                          className="action-icon text-danger"
                                        >
                                          {" "}
                                          <i className="mdi mdi-trash-can font-size-18"></i>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}

                                  <tr className="bg-light text-right">
                                    <th
                                      scope="row"
                                      colSpan="0"
                                      className="bg-light text-left"
                                    >
                                      <ButtonGroup size="sm">
                                        <Button color="success" type="submit">
                                          <i class="ri-add-line align-middle ml-2"></i>
                                          Enregistrer
                                        </Button>
                                        <Button
                                          color="danger"
                                          onClick={get_taxe}
                                        >
                                          Reinitialiser
                                          <i class="ri-close-line align-middle ml-2"></i>
                                        </Button>
                                      </ButtonGroup>
                                    </th>
                                    <th scope="row" colSpan="3">
                                      Total :
                                    </th>

                                    <td>$ {Totalcaart}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </form>
                        </Col>

                        <Col sm="6">
                          <h4 className="card-title">Statut commande</h4>
                          <div id="accordion">
                            {command.map((value, index) => {
                              const reports = [];
                              const key = index + 1;
                              let Appo = "";
                              console.log(
                                "my commande level appro ",
                                value.levelAprobation
                              );

                           

                              return (
                                <Card className="mb-1">
                                  <Link
                                    to="#"
                                    onClick={t_col1}
                                    style={{ cursor: "pointer" }}
                                    className="text-dark"
                                  >
                                    <CardHeader
                                      id="headingOne"
                                      stype={{ padding: "2px" }}
                                    >
                                      <h6 className="m-0 font-14">
                                        <i class="ri-arrow-right-circle-fill"></i>
                                        {` ${value.id_commande}  `}

                                        <Button
                                          color={
                                            value.status == 0
                                              ? "danger "
                                              : value.status == 1
                                              ? "warning"
                                              : "success" //success
                                          }
                                          className=" waves-effect waves-light mr-1 btn-sm"
                                        >
                                          {value.status == 0
                                            ? "En attente soumission "
                                            : value.status == 4
                                            ? "Commande Receptionée"
                                            : `En Attente Approbation Niveau ${value.Level}`}
                                        </Button>
                                        <i
                                          className={
                                            col1
                                              ? "mdi mdi-minus float-right accor-plus-icon"
                                              : "mdi mdi-plus float-right accor-plus-icon"
                                          }
                                        ></i>
                                      </h6>
                                    </CardHeader>
                                  </Link>
                                  <Collapse isOpen={col1}>
                                    <CardBody>
                                      {value.command_detail.map((k, index) => {
                                        const valueText = command_detail.filter(
                                          (x) => x.Id_taxe === k.taxe_id
                                        );
                                        console.log(
                                          "tetify",
                                          valueText[0].libelle
                                        );

                                        reports.push({
                                          icon: "ri-file-paper-2-line",
                                          title: valueText[0].libelle,
                                          value: `${k.Qte} `,
                                          rate: "",
                                          desc: `${k.Qte * k.montant} $`,
                                        });
                                      })}
                                      <Row>
                                        <MiniWidgets reports={reports} />
                                      </Row>
                                      <div className=" text-right">
                                        {value.status == 0 ? (
                                          <ButtonGroup size="sm">
                                            <Button
                                              color="success"
                                              type="submit"
                                              onClick={(e) => {
                                                handleSubmitToValidation(
                                                  e,
                                                  value.id,
                                                  value.id_commande
                                                );
                                              }}
                                            >
                                              <i class="ri-send-plane-fill align-middle ml-2"></i>
                                              Soumettre
                                            </Button>
                                            <Button
                                              color="primary"
                                              onClick={(e) => {
                                                handleSubmitToValidation(
                                                  e,
                                                  value.id,
                                                  value.id_commande
                                                );
                                              }}
                                            >
                                              {" "}
                                              <i class="ri-pencil-fill align-middle ml-2"></i>{" "}
                                              Editer{" "}
                                            </Button>{" "}
                                          </ButtonGroup>
                                        ) : (
                                          <span> Commande REceptioné</span>
                                        )}
                                      </div>
                                    </CardBody>
                                  </Collapse>
                                </Card>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6" className="p-3">
                      <Row>
                        <Col sm="12">
                          <h4 className="card-title">Capture stock</h4>
                          <CardText> stock Nationale </CardText>

                          <Row>
                            <MiniWidgets reports={reportData} />
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="7" className="p-3">
                      <Row>
                        <Col sm="12">
                          <CardText></CardText>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="8" className="p-3">
                      <Row>
                        <Col sm="12">
                          <CardText></CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => state.app;
export default connect(mapStatetoProps, { UPDATE })(CommandePAge);
