import {connect} from "react-redux";
import {UPDATE} from "../../../store/app/appActions";
import {FormGroup, Label, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {FormControlLabel, Grid, Switch, TextField} from "@material-ui/core";
import BlockLoader from "../../../apis/BlockLoader";
import {delay} from "../../../apis/delay";
import axios from "axios";
import CONSTS from "../../../apis/CONSTS";
import getHeader from "../../../apis/getHeader";
import {toast} from "react-toastify";

const init_form = {matricule: '', brand: '', is_active: true}

const VehiculesForm = (props) => {
    const {onClose, row, action, app} = props;
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState(init_form);

    // console.log(props)

    const handleClose = () => {
        if (loading) return;
        onClose({refresh: false});
    };
    useEffect(() => {
        let cancel;
        if (cancel) return
        setForm(init_form);
        console.log('form', row)
        if (action === 'edit') {
            console.log('form', action)
            const $form = {...row}
            delete $form.created_at
            delete $form.updated_at
            delete $form.edit_user
            delete $form.add_user
            setForm($form);
        }

        return () => {
            cancel = true;
        };
    }, [action]);

    const inputsChange = (e) => {
        const {name, value} = e.target;
        setForm({...form, [name]: value})
    }

    const handleCheckedChange = (event) => {
        setForm({...form, [event.target.name]: event.target.checked});
    };

    const onSubmit = async (e) => {
        const $form = {...form}
        console.log($form)
        e.preventDefault();
        const {st} = await postRequest($form);
        if (st) {
            onClose({refresh: true})
        }

    }

    const postRequest = async (form) => {
        setLoading(true);
        let st;
        try {
            const res = await axios({
                method: action === 'new' ? 'POST' : 'PUT',
                url: CONSTS.baseUrl + "app/vehicules?u=matricule",
                data: form,
                headers: getHeader(app.token)
            })
            console.log('API result', res.data);
            toast.success(res.data.msg)
            st = true;
        } catch (e) {
            console.error("React Error", e.response);
            const msg = e.response?.data?.msg || e.message;
            toast.error(msg);
        }
        setLoading(false);
        return {st}
    }

    if (loading) return <BlockLoader extraclass=''/>

    return <React.Fragment>
        <form className="form-horizontal"
              onSubmit={onSubmit}>

            <ModalHeader toggle={handleClose}>
                Formulaire vehicule
            </ModalHeader>

            <ModalBody>


                <div className='form-group'>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <i className="ri-keyboard-line auti-custom-input-icon"/>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="input-with-icon-grid" label="Plaque matricule"
                                autoComplete="off" required
                                value={form.matricule}
                                inputProps={
                                    {readOnly: action === 'edit'}
                                }
                                name="matricule" onChange={inputsChange}
                            />
                        </Grid>
                    </Grid>
                </div>


                <div className='form-group'>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <i className="ri-keyboard-line auti-custom-input-icon"/>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="input-with-icon-grid" label="Marque"
                                autoComplete="off" required
                                value={form.brand}
                                name="brand" onChange={inputsChange}
                            />
                        </Grid>
                    </Grid>
                </div>


                <FormGroup row className='px-4 mt-4'>
                    <FormControlLabel
                        labelPlacement="start"
                        control={<Switch checked={form.is_active} onChange={handleCheckedChange} name="is_active"/>}
                        label="Active"
                    />
                </FormGroup>


            </ModalBody>

            <ModalFooter>
                <button type="submit"
                        className="btn btn-primary">
                    Confirm
                </button>
            </ModalFooter>
        </form>
    </React.Fragment>;
};


const mapStateToProps = (state) => state;

const mapDispatchToProps = {
    UPDATE
}


export default connect(mapStateToProps, mapDispatchToProps)((withRouter(VehiculesForm)));


