import axios from "axios";
import CONSTS from "./CONSTS";
import randomUrlParams from "./randomUrlParams";
import getHeader from "./getHeader";
import {toast} from "react-toastify";

export async function apiGET(props,url) {
    let rep;
    // console.log("API GET",url, props.token)
    try {
        const res = await axios.get(
            CONSTS.baseUrl + `${url}&rand=` + randomUrlParams(),
            {headers: getHeader(props.token)}
        );
        rep = res.data;
    } catch (e) {
        const msg = e.response?.data?.msg || e.message;
        toast.error(msg);
        console.error("React Error", e.response);
    }

    return rep
}


export async function apiGETSimple(props,url) {
    let rep;
    try {
        const res = await axios.get(
            CONSTS.baseUrl + `${url}`,
            {headers: getHeader(props.token)}
        );
        rep = res.data.msg;
    } catch (e) {
        const msg = e.response?.data?.msg || e.message;
        toast.error(msg);
        console.error("React Error", e.response);
    }

    return rep
}
