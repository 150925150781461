import React, {Component} from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import {Link, withRouter} from "react-router-dom";

//i18n
import {withNamespaces} from "react-i18next";

import {connect} from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changePreloader,
    changeSidebarTheme,
    changeSidebarType,
} from "../../store/actions";
import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import randomUrlParams from "../../apis/randomUrlParams";
import getHeader from "../../apis/getHeader";

const ExtraSidebarContent = (props) => {
    const totalQuantity = props.mining_cart.reduce((acc, obj) => acc + obj.qte, 0)
    const {user_type, is_superuser} = props.user_info

    // console.log("PROPQS", props)

    return (
        <React.Fragment>

            {/*RAPPORT MENU*/}
            <li>
                <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-settings-line"/>
                    <span className="ml-1">{props.t("Rapports")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                    <li>
                        <Link to="/rapports/matieres">
                            <i className="ri-user-add-line"/>
                            {props.t("Matieres")}
                        </Link>
                    </li>

                </ul>
            </li>

            {/*SOUSCRIPTIONS BUREAU*/}
            {user_type === 'intern' ?
                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="ri-settings-line"/>
                        <span className="ml-1">{props.t("Ventes")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                        <li>
                            <Link to="/office/panier">
                                <i className="ri-user-add-line"/>
                                <span className="badge badge-pill badge-danger float-right">
                                {totalQuantity}
                            </span>
                                {props.t("Vendre")}
                            </Link>
                        </li>

                        <li>
                            <Link to="/office/souscriptions">
                                <i className="ri-user-add-line"/>
                                {props.t("Souscriptions")}
                            </Link>
                        </li>

                        <li>
                            <Link to="/gab/souscription" target='_blank'>
                                <i className="ri-user-add-line"/>

                                {props.t("GAB")}
                            </Link>
                        </li>

                        {/*  <li>
                            <Link to="/office/documents">
                                <i className="ri-user-add-line"/>

                                {props.t("Documents")}
                            </Link>
                        </li>*/}
                    </ul>
                </li>
                : null}

            {/*SOUSCRIPTIONS CLIENT*/}
            {user_type === 'extern' ?
                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="ri-settings-line"/>
                        <span className="ml-1">{props.t("Souscriptions")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                        <li>
                            <Link to="/client/panier">
                                <i className="ri-user-add-line"/>
                                <span className="badge badge-pill badge-danger float-right">
                                {totalQuantity}
                            </span>
                                {props.t("Mon Panier")}
                            </Link>
                        </li>

                        <li>
                            <Link to="/client/souscriptions">
                                <i className="ri-user-add-line"/>
                                {props.t("Mes Souscriptions")}
                            </Link>
                        </li>

                        <li>
                            <Link to="/client/documents">
                                <i className="ri-user-add-line"/>

                                {props.t("Mes Documents")}
                            </Link>
                        </li>
                    </ul>
                </li>
                : null}

            <li>
                <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-settings-line"/>
                    <span className="ml-1">{props.t("Operations Banque")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                    <li>
                        <Link to="/bank/check_pay">
                            <i className="ri-user-add-line"/>
                            {props.t("Check & pay")}
                        </Link>
                    </li>

                </ul>
            </li>

            <li>
                <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-settings-line"/>
                    <span className="ml-1">{props.t("Sortie")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                    <li>
                        <Link to="/sortie/declaration">
                            <i className="ri-user-add-line"/>
                            {props.t("Declaration")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/sortie/tracking">
                            <i className="ri-user-add-line"/>
                            {props.t("Tracking")}
                        </Link>
                    </li>

                </ul>
            </li>

            {/* STOCK MENU */}
            {/*{user_type === 'administrateur' || is_superuser ?*/}

            <li>
                <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-building-2-fill"/>
                    <span className="ml-1">{props.t("Stocks")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                    {/*{user_type === 'administrateur' || user_type === 'national' || is_superuser ?*/}
                    <li>
                        <Link to="/g-stock/warehouse">
                            <i className="ri-user-add-line"/>
                            {props.t("Central")}
                        </Link>
                    </li>
                    {/*: null}*/}

                    {/*{user_type === 'provincial' ?*/}
                    <li>
                        <Link to="/g-stock/province">
                            <i className="ri-user-add-line"/>
                            {props.t("Provincial")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/g-stock/bureau">
                            <i className="ri-user-add-line"/>
                            {props.t("Bureau")}
                        </Link>
                    </li>
                    {/*: null}*/}
                </ul>
            </li>
            {/*: null}*/}

            {/* MINING MENU */}

            <li>
                <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-building-2-fill"/>
                    <span className="ml-1">{props.t("Entités Mining")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">

                    <li>
                        <Link to="/mining/bureaux">
                            <i className="ri-user-add-line"/>
                            {props.t("Bureaux Etat")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/mining/checkpoints">
                            <i className="ri-user-add-line"/>
                            {props.t("Checkpoints")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/mining/exploitants">
                            <i className="ri-user-add-line"/>
                            {props.t("Exploitants")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/mining/concessions">
                            <i className="ri-user-add-line"/>
                            {props.t("Concessions")}
                        </Link>
                    </li>


                    <li>
                        <Link to="/mining/unites-transformation">
                            <i className="ri-user-add-line"/>
                            {props.t("Unités de transf.")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/mining/transitaires">
                            <i className="ri-user-add-line"/>
                            {props.t("Transitaires")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/mining/transporteurs">
                            <i className="ri-user-add-line"/>
                            {props.t("Transporteurs")}
                        </Link>
                    </li>


                    <li>
                        <Link to="/mining/vehicules">
                            <i className="ri-user-add-line"/>
                            {props.t("Vehicules")}
                        </Link>
                    </li>

                </ul>
            </li>

            {/*ADMIN MENU */}

            <li>
                <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-settings-line"/>
                    <span className="ml-1">{props.t("Admin")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                    <li>
                        <Link to="/admin/banks">
                            <i className="ri-user-add-line"/>
                            {props.t("Banques")}
                        </Link>
                    </li>

                    {/*<li>
                        <Link to="/admin/configs">
                            <i className="ri-user-add-line"/>
                            {props.t("Configurations")}
                        </Link>
                    </li>*/}

                    <li>
                        <Link to="/admin/postes-frontieres" disabled>
                            <i className="ri-user-add-line"/>
                            {props.t("Postes Frontaliers")}
                        </Link>
                    </li>


                    <li>
                        <Link to="/admin/substances">
                            <i className="ri-user-add-line"/>
                            {props.t("Substances")}
                        </Link>
                    </li>


                </ul>
            </li>


            {/*Users MENU */}
            <li>
                <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-settings-line"/>
                    <span className="ml-1">{props.t("Utilisateurs")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                    <li>
                        <Link to="/users/admin">
                            <i className="ri-user-add-line"/>
                            {props.t("Admin users")}
                        </Link>
                    </li>


                    <li>
                        <Link to="/users/mining">
                            <i className="ri-user-add-line"/>
                            {props.t("Mining Users")}
                        </Link>
                    </li>


                    <li>
                        <Link to="/users/etat">
                            <i className="ri-user-add-line"/>
                            {props.t("Agents Etat")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/users/checkpoint">
                            <i className="ri-user-add-line"/>
                            {props.t("Checkpoint users")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/users/national">
                            <i className="ri-user-add-line"/>
                            {props.t("National users")}
                        </Link>
                    </li>

                    <li>
                        <Link to="/users/province">
                            <i className="ri-user-add-line"/>
                            {props.t("Provincial users")}
                        </Link>
                    </li>

                </ul>

            </li>


        </React.Fragment>
    );

}

const mapStatetoProps = (state) => {
    return {...state.Layout, ...state.app};
};

export default withRouter(
    connect(mapStatetoProps, {})(withNamespaces()(ExtraSidebarContent))
);
