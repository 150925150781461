import React, {Component, useEffect, useState} from "react";
import {MDBDataTable} from "mdbreact";
import {Row, Col, Card, CardBody, Container} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";

import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import randomUrlParams from "../../apis/randomUrlParams";
import {toast} from "react-toastify";
import getHeader from "../../apis/getHeader";
import {connect} from "react-redux";
import {UPDATE} from "../../store/app/appActions";
import MUIDataTable from "mui-datatables";

const TransactionsLocataires = (props) => {
    const [row, setRow] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const ApiGetData = async () => {
        setLoading(true)
        let rep;
        const {id} = props.user_info
        try {
            const res = await axios.get(CONSTS.baseUrl + `impot/contrat/${id}?rand=` + randomUrlParams(),
                {headers: getHeader(props.token)});
            //setOriginal(res.data.msg);
            rep = res.data.msg

        } catch (e) {
            const msg = e.response?.data?.msg || e.message;
            toast.error(msg)
            console.error("React Error", e.response);
        }
        setLoading(false)

        return rep
    }

    const get_data = async ($data) => {
        const res = await ApiGetData();
        if (res) {
            setRow(res);
            const new_data = res
            console.log('res API', new_data)
            setData(new_data)
        }
    }

    useEffect(() => {

        //setOriginal($data);
        get_data();
    }, []);

    const column = [
        {
            label: "ID",
            name: "id",
            options: {display: false, print: false, viewColumns: false}
        },
        {
            label: "#",
            name: "irl",
            options: {
                customBodyRender: (val, {rowIndex}) => {
                    // console.log('val', val, data[rowIndex])
                    const pending_amount = data[rowIndex]
                    const color = pending_amount === 0 ? 'green' : 'red';
                    return <span><i className="ri-arrow-up-circle-fill"
                                    style={{fontSize: '20px', color}}/></span>
                },
                customHeadLabelRender: (obj) => (<b>{obj.label}</b>)
            }
        },
        {
            label: "Numero IRL",
            name: "irl",
            options: {
                customHeadLabelRender: (obj) => (<b>{obj.label}</b>),
                customBodyRender: (val, {rowData}) => {
                    const $row = data.find(i => i.id === rowData[0])
                    return $row?.locataire?.irl
                },
            }
        },
        {
            label: "Identite",
            name: "identity",
            options: {
                customHeadLabelRender: (obj) => (<b>{obj.label}</b>),
                customBodyRender: (val, {rowData}) => {
                    const $row = data.find(i => i.id === rowData[0])
                    return $row?.locataire?.identity
                },
            }
        },
        {
            label: "Etablissement",
            name: "etablissement",
            options: {customHeadLabelRender: (obj) => (<b>{obj.label}</b>)}
        },

        {
            label: "Total à payer",
            name: "total_amount",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (obj) => (<b>{obj.label}</b>)
            }
        },

        {
            label: "Dettes",
            name: "total_amount",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (obj) => (<b>{obj.label}</b>)
            }
        },

    ];


    const breadcrumbItems = [
        {title: "Home", link: "#"},
        {title: "Bailleurs", link: "#"},
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Situations Locataires"
                        breadcrumbItems={breadcrumbItems}
                    />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>

                                    <MUIDataTable
                                        title={"Données pour " + props.user_info.identity}
                                        data={data}
                                        columns={column}
                                        options={{
                                            filterType: 'checkbox',
                                            elevation: 0,
                                            selectableRowsHeader: false,
                                            selectableRows: false
                                        }}
                                    />
                                    {/*<MDBDataTable responsive striped
                                                  data={data}
                                                  noBottomColumns={true}/>*/}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = state => state.app

export default connect(mapStatetoProps, {UPDATE})(TransactionsLocataires);

