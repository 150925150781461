import React, {Component} from "react";
import {Switch, BrowserRouter as Router} from "react-router-dom";
import {connect} from "react-redux";
import 'react-confirm-alert/src/react-confirm-alert.css';

// Import Routes
import {authProtectedRoutes, publicRoutes} from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';
import "./print.scss";
import "./docs.scss";
import 'antd/dist/antd.css';
import "./theme.scss";
import "./mcustom.scss";


//Fake backend
import fakeBackend from './helpers/AuthType/fakeBackend';

//Firebase helper
import {initFirebaseBackend} from "./helpers/firebase_helper";
import {ToastContainer} from "react-toastify";
import {authProtectedRoutes2} from "./routes/index2";
import {publicRoutes2} from "./routes/public";

// Activating fake backend
//fakeBackend();

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// init firebase backend
//initFirebaseBackend(firebaseConfig);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getLayout = this.getLayout.bind(this);
    }

    /**
     * Returns the layout
     */
    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    render() {
        const Layout = this.getLayout();

        //console.log('CONNECTED',this.props.app.connected)

        return (
            <React.Fragment>
                <ToastContainer hideProgressBar={true}/>
                <Router>
                    <Switch>

                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx + route.path}
                                isAuthProtected={false}
                            />
                        ))}


                        {publicRoutes2.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={true}
                                guard={route.guard}
                                connected={this.props.app.connected}
                            />
                        ))}

                        {authProtectedRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={Layout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={true}
                                connected={this.props.app.connected}
                            />
                        ))}

                        {authProtectedRoutes2.map((route, idx) => {
                            return (
                                <AppRoute
                                    path={route.path}
                                    layout={Layout}
                                    component={route.component}
                                    guard={route.guard}
                                    key={idx}
                                    isAuthProtected={true}
                                    connected={this.props.app.connected}
                                />
                            );
                        })}

                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
        app: state.app
    };
};


export default connect(mapStateToProps, null)(App);
