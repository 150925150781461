import {IconButton, Tooltip, withStyles} from "@material-ui/core";
import React, {useEffect, useState} from 'react';
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import randomUrlParams from "../../apis/randomUrlParams";
import getHeader from "../../apis/getHeader";
import {toast} from "react-toastify";
import BlockLoader from "../../apis/BlockLoader";

const defaultToolbarStyles = {
    iconButton: {},
};

const AddToolBar = (props) => {
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        //setOriginal($data);
    }, []);


    const handleClick = async () => {
        if (loading) return
        props.refresh();

    }

    const {classes} = props;

    return (
        <React.Fragment>
            <Tooltip title={"Ajouter"}>
                <IconButton className={classes.iconButton} onClick={handleClick}>
                    {loading ? <i className="ri-loader-2-line"/> : <i className="ri-add-circle-line "
                    />}
                </IconButton>


            </Tooltip>

        </React.Fragment>
    );


}

export default withStyles(defaultToolbarStyles, {name: "CustomToolbar"})(AddToolBar);

