import { connect } from "react-redux";
import { UPDATE } from "../../../store/app/appActions";
import {
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import BlockLoader from "../../../apis/BlockLoader";
import { delay } from "../../../apis/delay";
import axios from "axios";
import CONSTS from "../../../apis/CONSTS";
import getHeader from "../../../apis/getHeader";
import { toast } from "react-toastify";
import AutoCompSearchInput from "../../../components/Common/AutoCompSearchInput";

const init_form = { matricule: "", brand: "", is_active: true };

const AutorisationForm = (props) => {
  const { onClose, row, action, app } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(init_form);

  // console.log(props)

  const handleClose = () => {
    if (loading) return;
    onClose({ refresh: false });
  };
  useEffect(() => {
    let cancel;
    if (cancel) return;
    setForm(init_form);
    console.log("form", row);
    if (action === "edit") {
      console.log("form", action);
      const $form = { ...row };
      delete $form.created_at;
      delete $form.updated_at;
      delete $form.edit_user;
      delete $form.add_user;
      setForm($form);
    }

    return () => {
      cancel = true;
    };
  }, [action]);

  const inputsChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleCheckedChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    const $form = { ...form };
    console.log($form);
    e.preventDefault();
    const { st } = await postRequest($form);
    if (st) {
      onClose({ refresh: true });
    }
  };

  const postRequest = async (form) => {
    setLoading(true);
    let st;
    try {
      const res = await axios({
        method: action === "new" ? "POST" : "PUT",
        url: CONSTS.baseUrl + "app/vehicules?u=matricule",
        data: form,
        headers: getHeader(app.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      st = true;
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  if (loading) return <BlockLoader extraclass="" />;

  return (
    <React.Fragment>
      <form className="form-horizontal" onSubmit={onSubmit}>
        <ModalHeader toggle={handleClose}>
          Edition Autorisation de Transport
        </ModalHeader>

        <ModalBody>
          <Col md={12}>
            <form>
              <Card>
                <CardHeader style={{ backgroundColor: "teal" }}>
                  <span style={{ color: "white" }}>
                    {" "}
                    1. IDENTITE DU TITULAIRE
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="form-group col-md-12">
                      <AutoCompSearchInput
                        //  url={`mining/client?bureau=${bureau_id}&fields=name,id_client,province,/province_id,ville,ville_id,commune&st=1`}
                        //      value={props.mvt_client}
                        app={props}
                        append_all={false}
                        label="Denomination ou raison sociale"
                        //  onUpdate={(val) =>
                        //    props.UPDATE(MVT_CLIENT, val);
                        //get_data(active, false, val)
                        //  }}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Numéros d'identification national
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Nouveau numéros du registre de commerce
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth={true}
                        />
                      </FormControl>
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Siege Sociale d'exploitation
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i class="ri-file-text-line"></i>
                          Lettre d'immatriculation banque du Congo
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth={true}
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i class="ri-bank-line"></i>
                          Numéros de compte bancaire a l'etranger et en
                          republique democratique du Congo
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth={true}
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i class="ri-bank-fill"></i>
                          Numéros d'immatriculation nationale fiscal
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth={true}
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader style={{ backgroundColor: "teal" }}>
                  <span style={{ color: "white" }}>
                    {" "}
                    2. RENSEIGNEMENT SUR LE DROIT MINIER
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Nature du Droit minier
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Numéros de l'arrêté
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Num Titre minier
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i
                            class="ri-calendar-fill"
                            style={{ color: "green" }}
                          ></i>
                          Période de validité
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i
                            class="ri-map-pin-2-fill  auti-custom-input-icon"
                            style={{ color: "green" }}
                          ></i>
                          Localisation du Titre
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i
                            class="ri-arrow-right-fill"
                            style={{ color: "green" }}
                          ></i>
                          Substance (s) exploité (s)
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader style={{ backgroundColor: "teal" }}>
                  {" "}
                  <span style={{ color: "white" }}>
                    3. RENSEIGNEMENT SUR LES PRODUITS MINIES MARCHANDS
                    TRANSPORTES
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          N° de la demande de Trasport
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Nature des produits
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Lot</InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">PVC :</InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">PVPE</InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Certificat</InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Quantité</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Tonne</InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Qualité</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Nombre de colis
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Prix de Vente
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader style={{ backgroundColor: "orange" }}>
                  <span style={{ color: "black" }}>
                    {" "}
                    Provenance des produits Transportés
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Provenance d'extraction
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i
                            class="ri-flag-fill  auti-custom-input-icon"
                            style={{ color: "red" }}
                          ></i>
                          Emplacement du Site
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i class="ri-building-2-fill auti-custom-input-icon"></i>
                          Nom de la Zone d'Exploitation
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i class="ri-building-2-fill auti-custom-input-icon"></i>
                          Chantier(s) d'extraction
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i
                            class="ri-map-pin-2-fill  auti-custom-input-icon"
                            style={{ color: "green" }}
                          ></i>
                          Localité /Groupement
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i class="ri-map-pin-2-fill"></i>
                          Secteur
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i class="ri-map-pin-2-fill"></i>
                          Territoire
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i
                            class="ri-arrow-right-fill"
                            style={{ color: "green" }}
                          ></i>
                          Chantier d'Extraction
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader style={{ backgroundColor: "orange" }}>
                  {" "}
                  <span style={{ color: "black" }}>
                    Unité de Traitement ou de Transformation
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="form-group col-md-12">
                      <Grid container spacing={0} alignItems="flex-end">
                        <Grid item xs={1}>
                          <i className="ri-search-line auti-custom-input-icon" />
                        </Grid>
                        <Grid item xs={11}>
                          <AutoCompSearchInput
                            //  url={`mining/client?bureau=${bureau_id}&fields=name,id_client,province,/province_id,ville,ville_id,commune&st=1`}
                            //      value={props.mvt_client}
                            app={props}
                            append_all={false}
                            label="Nom"
                            //  onUpdate={(val) =>
                            //    props.UPDATE(MVT_CLIENT, val);
                            //get_data(active, false, val)
                            //  }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Adresse</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Province de Transite
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Province d'exportation
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i
                            class="ri-arrow-right-fill"
                            style={{ color: "green" }}
                          ></i>
                          Destination des produits miniers marchands transportés
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader style={{ backgroundColor: "teal" }}>
                  {" "}
                  <span style={{ color: "white" }}>
                    4. RENSEIGNEMENT RELATIF DU TRANSPORTEUR
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="form-group col-md-6">
                      <AutoCompSearchInput
                        //  url={`mining/client?bureau=${bureau_id}&fields=name,id_client,province,/province_id,ville,ville_id,commune&st=1`}
                        //      value={props.mvt_client}
                        app={props}
                        append_all={false}
                        label="Transporteur"
                        //  onUpdate={(val) =>
                        //    props.UPDATE(MVT_CLIENT, val);
                        //get_data(active, false, val)
                        //  }}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <AutoCompSearchInput
                        //  url={`mining/client?bureau=${bureau_id}&fields=name,id_client,province,/province_id,ville,ville_id,commune&st=1`}
                        //      value={props.mvt_client}
                        app={props}
                        append_all={false}
                        label="Transitaire"
                        //  onUpdate={(val) =>
                        //    props.UPDATE(MVT_CLIENT, val);
                        //get_data(active, false, val)
                        //  }}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Denomination Sociale :
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Adresse</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Téléphone</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Autrisation de survol et autres</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Mode de transport :</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Immatriculation Camion :</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                         Itineraire :
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                          disabled="True"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </form>
          </Col>
        </ModalBody>

        <ModalFooter>
          <button type="submit" className="btn btn-success">
            Enregistrer
          </button>
        </ModalFooter>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = {
  UPDATE,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AutorisationForm));
