import { connect } from "react-redux";
import { UPDATE } from "../../../store/app/appActions";
import {
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Table,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import BlockLoader from "../../../apis/BlockLoader";
import { delay } from "../../../apis/delay";
import axios from "axios";
import CONSTS from "../../../apis/CONSTS";
import getHeader from "../../../apis/getHeader";
import { toast } from "react-toastify";
import AutoCompSearchInput from "../../../components/Common/AutoCompSearchInput";

const init_form = { matricule: "", brand: "", is_active: true };

const CertificatForm = (props) => {
  const { onClose, row, action, app } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(init_form);

  // console.log(props)

  const handleClose = () => {
    if (loading) return;
    onClose({ refresh: false });
  };
  useEffect(() => {
    let cancel;
    if (cancel) return;
    setForm(init_form);
    console.log("form", row);
    if (action === "edit") {
      console.log("form", action);
      const $form = { ...row };
      delete $form.created_at;
      delete $form.updated_at;
      delete $form.edit_user;
      delete $form.add_user;
      setForm($form);
    }

    return () => {
      cancel = true;
    };
  }, [action]);

  const inputsChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleCheckedChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    const $form = { ...form };
    console.log($form);
    e.preventDefault();
    const { st } = await postRequest($form);
    if (st) {
      onClose({ refresh: true });
    }
  };

  const postRequest = async (form) => {
    setLoading(true);
    let st;
    try {
      const res = await axios({
        method: action === "new" ? "POST" : "PUT",
        url: CONSTS.baseUrl + "app/vehicules?u=matricule",
        data: form,
        headers: getHeader(app.token),
      });
      console.log("API result", res.data);
      toast.success(res.data.msg);
      st = true;
    } catch (e) {
      console.error("React Error", e.response);
      const msg = e.response?.data?.msg || e.message;
      toast.error(msg);
    }
    setLoading(false);
    return { st };
  };

  if (loading) return <BlockLoader extraclass="" />;

  return (
    <React.Fragment>
      <form className="form-horizontal" onSubmit={onSubmit}>
        <ModalHeader toggle={handleClose}>
          Edition Certificat d'origine à l'exploitation
        </ModalHeader>

        <ModalBody>
          <Col md={12}>
            <form>
              <Card>
                <CardHeader style={{ backgroundColor: "teal" }}>
                  <span style={{ color: "WHITE" }}> Information générale </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Numéros chargement
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Client</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth={true}
                        />
                        <FormHelperText id="my-helper-text">
                          Numéro d'enregistrment du lot
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Numéro licence
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">Lot</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-4">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">détail lot</InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth={true}
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader style={{ backgroundColor: "orange" }}>
                  <span style={{ color: "black" }}> Autres informations</span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          Date Evaluation
                        </InputLabel>
                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i
                            class="ri-flag-fill  auti-custom-input-icon"
                            style={{ color: "red" }}
                          ></i>
                          Poste Sortie
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i class="ri-building-2-fill auti-custom-input-icon"></i>
                          Date d'expiration
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-md-6">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i class="ri-building-2-fill auti-custom-input-icon"></i>
                          Nom et Adresse destinataire
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>

                    <div className="form-group col-md-12">
                      <FormControl fullWidth="true">
                        <InputLabel htmlFor="my-input">
                          <i
                            class="ri-map-pin-2-fill  auti-custom-input-icon"
                            style={{ color: "green" }}
                          ></i>
                          Transité par
                        </InputLabel>

                        <Input
                          id="my-input"
                          aria-describedby="my-helper-text"
                          fullWidth="true"
                        />
                        <FormHelperText id="my-helper-text"></FormHelperText>
                      </FormControl>
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader style={{ backgroundColor: "ORANGE" }}>
                  {" "}
                  <span style={{ color: "BLACK" }}>Tableaux des produits</span>
                </CardHeader>
                <CardBody>
                  <Table className="table-centered mb-0 table-nowrap">
                    <thead className="bg-light">
                      <tr>
                        <th>Element Majeur </th>
                        <th>Poids net</th>
                        <th>Tenneur %</th>
                        <th>Metal Contenus</th>
                        <th>Valeur USD</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </Table>
                </CardBody>
              </Card>
            </form>
          </Col>
        </ModalBody>

        <ModalFooter>
          <button type="submit" className="btn btn-success">
            Enregistrer
          </button>
        </ModalFooter>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = {
  UPDATE,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CertificatForm));
