import React, {Component, useEffect, useState} from "react";
import {MDBDataTable} from "mdbreact";
import {Row, Col, Card, CardBody, Container} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";

import axios from "axios";
import CONSTS from "../../apis/CONSTS";
import randomUrlParams from "../../apis/randomUrlParams";
import {toast} from "react-toastify";
import getHeader from "../../apis/getHeader";
import {connect} from "react-redux";
import {UPDATE} from "../../store/app/appActions";
import MUIDataTable from "mui-datatables";

const ListBailleurs = (props) => {
    const [row, setRow] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const ApiGetData = async () => {
        setLoading(true)
        let rep;
        try {
            const res = await axios.get(CONSTS.baseUrl + "user/landlords?rand=" + randomUrlParams(),
                {headers: getHeader(props.token)});
            //setOriginal(res.data.msg);
            rep = res.data.msg

        } catch (e) {
            const msg = e.response?.data?.msg || e.message;
            toast.error(msg)
            console.error("React Error", e.response);
        }
        setLoading(false)

        return rep
    }

    const get_data = async ($data) => {
        const res = await ApiGetData();
        if (res) {
            setRow(res);
            const new_data = res
            console.log('res API', new_data)
            setData(new_data)
        }
    }

    useEffect(() => {

        get_data();
    }, []);

    const column = [
        {
            label: "Numero ID",
            name: "irl",
            options: {customHeadLabelRender: (obj) => (<b>{obj.label}</b>)}
        },
        {
            label: "Identite",
            name: "identity",
            options: {customHeadLabelRender: (obj) => (<b>{obj.label}</b>)}
        },

        {
            label: "Télephone",
            name: "phone",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (obj) => (<b>{obj.label}</b>)
            }
        },
        {
            label: "Télephone 2",
            name: "phone",
            options: {
                filter: true,
                sort: true,
                display: true,
                customHeadLabelRender: (obj) => (<b>{obj.label}</b>)
            }
        },

        {
            label: "Locataires",
            name: "tenants",
            options: {
                display: true,
                customHeadLabelRender: (obj) => (<b>{obj.label}</b>)
            }
        },
        {
            label: "Emails",
            name: "email",
            options: {
                display: false,
                customHeadLabelRender: (obj) => (<b>{obj.label}</b>)
            }
        },

    ];

    const breadcrumbItems = [
        {title: "Home", link: "#"},
        {title: "Bailleurs", link: "#"},
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Base de données Bailleurs"
                        breadcrumbItems={breadcrumbItems}
                    />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody className='ml-2 col-md-12'>
                                    <MUIDataTable
                                        title={"Données"}
                                        data={data}
                                        columns={column}
                                        options={{
                                            filterType: 'checkbox',
                                            elevation: 0,
                                            selectableRowsHeader: false,
                                            selectableRows: false
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = state => state.app

export default connect(mapStatetoProps, {UPDATE})(ListBailleurs);

